/* News Aggregator */

// MAKE LIQUID!!!!
// 1. Mixins
// 2. Global Styles
// 3. Expanded View
// 4. Condensed View
// 5. Comment Count and Read More
// 1. Mixins (Remove when liquid)
@mixin newsagg-header {
  font-size: em(24);
  font-weight: 700;
  text-transform: none;
  line-height: 1.3em;
  font-family: $fontOne;
  margin-bottom: 6px;
  letter-spacing: -0.5px;
  @media only screen and (max-width: 768px) {
    font-size: em(17);
  }
  a {
    color: #444444;
    font-weight: 700;
    transition: all $transition-standard; //line-height: normal;
    &:hover {
      color: #444;
      text-decoration: underline;
    }
  }
}

@mixin newsagg-image {
  display: inline-block;
  float: left;
  padding-right: 0px;
  border: 1px solid #ccc;
  img {
    width: auto;
    display: block;
    width: 160px;
    transition: opacity 1s ease-in;
    @media only screen and (max-width: 768px) {
      width: 100px;
    }
  }
  .user_mode .one-up &,
  .user_mode .two-up & {
    padding: 0;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .user_mode .two-up & {
    width: 100%;
    margin-bottom: 15px;
    @media only screen and (max-width: 768px) {
      width: 50%;
      margin-right: 15px;
      margin-bottom: 0;
      padding-bottom: 25% !important;
    }
    @media only screen and (max-width: 400px) {
      width: 50%;
    }
  }
}

@mixin newsagg-date-author {
  color: #666;
  font-size: em(12);
  line-height: normal;
  display: inline-block;
  margin-right: .5em;
  display: none;
}

@mixin newsagg-teaser {
  font-family: $fontTwo;
  font-size: em(16);
  font-weight: 500;
  text-transform: none;
  line-height: normal;
  color: #444444;
  margin: 0;
  @media only screen and (max-width: 768px) {
    display: none;
    .one-up & {
      display: block;
    }
  }
}

//hide share counter
.at_flat_counter {
  display: none;
}

#NewsArticles.collectorElement {
  .bottomOptions {
    display: none;
  }
  .item.articleHasImage {
    >a img {
      display: inline-block;
      float: left;
      padding-right: 0px;
      border: 1px solid #ccc;
      width: auto;
      display: block;
      width: 160px;
      transition: opacity 1s ease-in;
      @supports (object-fit: cover) {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
      @media only screen and (max-width: 768px) {
        width: 100%; //100px;
      }
    } // actually target h4
    >h4 {
      @include newsagg-header;
    }
    .dateAuthor {
      line-height: 1;
    }
    .newsAuthor,
    .newsDate {
      @include newsagg-date-author;
    }
  }
  li {
    line-height: 1;
  }
  >a {
    overflow: hidden;
    max-height: 100px;
    @media only screen and (max-width: 768px) {
      max-height: 65px;
    }
    .newsAggregatorElement & {
      @include newsagg-image;
      img {
        opacity: 0;
      }
    }
  }
  .teaser {
    @include newsagg-teaser;
    text-transform: none;
  } //details legacy but may be needed
  .details,
  >ul {
    list-style-type: none;
    h4 {
      @include newsagg-header;
    }
  }
  .tags {
    display: none;
  }
  .details {
    padding-left: 175px;
    @media only screen and (max-width: 768px) {
      padding-left: 115px;
    }
  }
}

// 2. Global styles
.collectorElement,
.newsAggregatorElement {
  .item {
    padding: $base-padding 0;
    border: none;
    border-image: none;
    border-bottom: solid 1px #cccccc;
    @media only screen and (max-width: 768px) {
      padding: 10px 0;
    }
    &:nth-of-type(1) {
      padding-top: 0;
    }
    &:last-of-type {
      border-bottom: none;
    }
    @media only screen and (max-width: 768px) {
      //border-bottom: none;
    }
  }
  .extendedOptions {
    color: #fff;
    a {
      color: $accent-color;
    }
  }
  .dateAuthor {
    line-height: 1;
  }
  .newsAuthor,
  .newsDate {
    @include newsagg-date-author;
  }
  .articleHasImage {
    >h4,
    .newsText,
    .commentsAndMore,
    .newsItemHeader {
      padding-left: 0;
    }
    .newsItemHeader {
      position: relative;
      >a {
        position: relative;
        top: -2px;
        left: 0;
        color: #444444;
      }
    }
  } // Full-width agg
  .user_mode .one-up &,
  .user_mode .two-up & {
    @media only screen and (max-width: 768px) {
      margin-bottom: 3px;
    }
    .newsAggregatorElement .articleHasImage .newsItemHeader>a .articleHasImage {
      >h4,
      .newsText,
      .commentsAndMore,
      .newsItemHeader {
        padding-left: 0;
      }
      .newsItemHeader>a {
        margin-bottom: 15px;
      }
    }
    .item {
      padding-top: 0;
      &:last-of-type {
        border-bottom: solid 1px #cccccc;
      }
      >a {
        margin-bottom: 15px;
      }
      @media only screen and (max-width: 768px) {
        border-bottom: none;
        &:last-of-type {
          border-bottom: none;
          padding-bottom: 0;
        }
        >a {
          margin-bottom: 0px !important;
        }
      }
    }
  }
  .user_mode .one-up & {
    .item {
      padding-bottom: 35px;
      @media only screen and (max-width: 768px) {
        padding-bottom:0px;
      }
    }
    .condensed>a {
      display: block;
      width: 100%;
      height: 0px;
			//padding-bottom: 50% !important;
			padding-bottom: 62.5% !important;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover; object-position: center;';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .user_mode .two-up & {
    padding-top: 15px;
    width: 100%;
    margin-bottom: 15px;
    padding-left: 0px;
    border-bottom: 1px solid #ccc;
    @media only screen and (max-width: 768px) {
			border-bottom: 0px;

			.item{
				position:relative;
				&:before {
					content: '';
					width: 100%;
					height: 1px;
					display: block;
					background-color: #ccc;
					border-radius: 2% / 100%;
					position: absolute;
					top: -3px;
					left: 0;
			}
        &.odd:before {
					padding-top: 0 !important;
					margin-bottom: 5px !important;
					//top: -5px;
        }
			}
    }
    .item {
      border-bottom: 0px;
    }
    .item:not(.extendedOptions) {
      @media only screen and (max-width: 768px) {
        padding-bottom:0px;
        width: 100%;
        padding-right: 15px;
        margin-bottom: 0;
        display: flex;
        align-items: left;
        justify-content: left;
        >a,
        >ul {
          width: 50%;
				}

      }
    }
    .condensed>ul h4 {
      @media only screen and (max-width: 768px) {
        font-size: 17px;
      }
    }
    .condensed>a {
      display: block;
      width: 100%;
      height: 0px;
			//padding-bottom: 50% !important;
			padding-bottom: 62.5% !important;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover; object-position: center;';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .user_mode .two-up & {
    .item {
      width: 50%;
      float: left;
      &.odd {
        padding-right: 10px;
      }
      &.even {
        padding-left: 10px;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 10px 0 !important;
				border-bottom: none;
      }
    }
    .teaser {
      display: none;
    }
	}

}

//
.aggHeader {
  @include special-header;
  margin-bottom: 15px;
  a {
    font-weight: 700;
  }
}

// 3. Expanded Previews
.expanded {
  .newsItemHeader>a {
    @include newsagg-image;
    img {
      opacity: 0;
    }
  }
  .newsItemElement {
    h4 {
      @include newsagg-teaser;
    }
  }
  .newsItemHeader {
    h2 {
      @include newsagg-header;
    }
  }
  .newsText {
    clear: both;
    margin-top: 1em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: none;
      font-weight: 600;
      font-size: 1em;
      letter-spacing: normal;
      margin: 0 0 .5em 0;
      padding: 0;
    }
    ul {
      list-style-type: disc;
    }
  }
  @media only screen and (max-width: 768px) {
    .one-up & {
      .newsItemHeader h2 {
        font-size: 24px;
      }
      .newsAggregatorElement .item {
        padding-bottom: 0;
      }
      .condensed,
      .expanded {
        .teaser {
          display: block;
        }
      }
    }
    .two-up & {
      .newsItemHeader h2 {
        font-size: 18px;
      }
    }
  }
}

.trim,
.compressed {
  .newsAggregatorElement {
    .item {
      padding: 15px 0;
      >a {
        display: none;
      }
      @media only screen and (max-width: 768px) {
        border-bottom: solid 1px #cccccc;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
    .articleHasImage .newsItemHeader>a {
      display: none;
    }
  }
  .details h4,
  .condensed>ul h4 {
    font-size: 16px !important;
  }
  .expanded {
    .newsItemHeader h2 {
      font-size: 16px;
      font-family: $fontOne;
    }
    .newsItemElement h4 {
      display: none;
    }
  }
  .condensed {
    .details {
      padding-left: 0 !important;
    }
    .teaser {
      display: none;
    }
  }
}

.show-date {
  .teaser {
    display: none;
  }
  .dateAuthor,
  .newsDate {
    display: block !important;
    @media only screen and (max-width: 768px) {
      display: none !important;
    }
  }
}

// Has Header
.newsAggregatorElement.has-header .item:nth-of-type(1) {
  padding-top: 0;
}

// Around the hub
.user_mode .around-the-hub {
  .pageElement.newsAggregatorElement {
    margin: 0;
  }
  .hub-news {
    width: 50%;
    float: left;
    margin-bottom: 15px;
    &:nth-of-type(odd) {
      padding-right: 20px;
      border-right: solid 1px #cccccc;
    }
    &:nth-of-type(even) {
      padding-left: 20px;
    }
    .newsAggregatorElement .item {
      &:last-of-type {
        border-bottom: none;
      }
    }
    .aggHeader {
      font-family: $fontOne;
      font-size: 16px;
      font-weight: bold;
      text-transform: none;
    }
    @media only screen and (max-width: 640px) {
      width: 100%;
      border: none !important;
      padding: 0 !important;
    }
  } // Hub agg rows
  .hub-agg-row {
    margin-bottom: 15px;
    border-bottom: solid 1px #cccccc;
    &:last-of-type {
      border-bottom: none;
    }
    &:after {
      content: '';
      clear: both;
      display: block;
    }
    @media only screen and (max-width: 640px) {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}

// 4. Condensed Previews
.condensed {
  li {
    line-height: 1;
  }
  >a {
    overflow: hidden;
    max-height: 100px;
    @media only screen and (max-width: 768px) {
      max-height: 65px;
    }
    .newsAggregatorElement & {
      @include newsagg-image;
      img {
        opacity: 0;
      }
    }
  }
  .teaser {
    @include newsagg-teaser;
  }
  .details,
  >ul {
    list-style-type: none;
    h4 {
      @include newsagg-header;
    }
  }
  .details {
    padding-left: 175px;
    @media only screen and (max-width: 768px) {
      padding-left: 115px;
    }
  }
  .user_mode .one-up &,
  .user_mode .two-up & {
    .details {
      padding-left: 0;
    }
    >a {
      overflow: visible;
      max-height: none;
    }
  }
  .user_mode .one-up &>ul h4 {
    font-size: em(32);
    letter-spacing: -0.5px;
    @media only screen and (max-width: 768px) {
      font-size: em(24);
    }
  }
  .user_mode .two-up & {
    @media only screen and (max-width: 400px) {
      //.details { padding-left: 35%; }
    }
  }
}

// 5. Read more and comments
.commentCount {
  .button-commentCount {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    line-height: 1;
    &:hover {
      background: none;
    }
    .button-image-right {
      display: none;
    }
    .button-content {
      margin: 0;
      padding: 0;
      position: relative;
      border: none;
      background: none;
      color: $link-color;
      font-weight: 600;
      top: 2px;
      transition: all $transition-standard;
      &:hover {
        background: none;
        border: none;
        color: $hover-color;
      }
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        font-family: FontAwesome;
        line-height: 1;
        content: "\f0e5";
        top: -1px;
        right: 0px;
        padding-right: .25em;
      }
    }
  }
}

.commentsAndMore {
  line-height: 1;
  margin: 5px 0 0 0;
  display: none;
  .readMore {
    display: inline-block;
    margin-right: .5em;
    a {
      margin: 0;
    }
  }
}

// 7. Responsive
@include page-element-break(400) {
  .newsAggregatorElement {
    .articleHasImage {
      >h4,
      .newsText,
      .commentsAndMore,
      .newsItemHeader {
        padding-left: 0;
      }
      .newsItemHeader {
        >a {
          position: relative;
          top: initial;
          left: initial;
        }
      }
    }
    .item>a {
      position: relative;
      top: initial;
      left: initial;
    }
    .condensed .details {
      padding-left: 115px;
    }
  }
}

@include page-element-break(300) {
  .newsAggregatorElement {
    .articleHasImage {
      >h4,
      .newsText,
      .commentsAndMore,
      .newsItemHeader {
        padding-left: 0;
      }
      .newsItemHeader {
        >a {
          position: relative;
          float: none;
        }
      }
    }
    .item>a {
      position: relative; //float: none;
    }
    .condensed .details {
      //padding-left: 0;
    }
  }
}

// Hoping to get rid of these gross mqs when we figure out our breakpoints and fully implement bootstrap
// Until then, these edge cases will have to be defined outside of the "page-element-break" mixin
@media only screen and (min-width: 769px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-3,
    .col-md-6 {
      .newsAggregatorElement {
        .articleHasImage {
          >h4,
          .newsText,
          .commentsAndMore,
          .newsItemHeader {
            padding-left: 0;
          }
          .newsItemHeader {
            >a {
              position: relative;
              top: -2px;
              left: 0;
            }
          }
        }
        .condensed>a {
          display: inline-block;
          float: left;
          padding: 7px 15px 7px 0px;
        }
        .condensed .details {
          padding-left: 90px;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-3,
    .col-md-6 {
      .newsAggregatorElement {
        .articleHasImage {
          >h4,
          .newsText,
          .commentsAndMore,
          .newsItemHeader {
            padding-left: 0;
          }
          .newsItemHeader {
            >a {
              position: relative;
              display: block;
              float: none;
            }
          }
        }
        .condensed>a {
          display: block;
          float: none;
        }
        .condensed .details {
          padding-left: 0;
        }
      }
    }
  }
}

.trim,
.compressed {
  h2,
  h4 {
    a {
      font-weight: 600 !important;
    }
  }
}

.pageEl {
  &.sn-agg-time {
    .item {
      .dateAuthor {
        padding-top: 10px;
        @media only screen and (max-width: 768px) {
          display: none;
        }
        .newsDate,
        .newsAuthor {
          display: none;
        }
      }
      h4 {
        font-size: 17px;
        font-weight: 700;
        line-height: 1.3em;
        color: #444;
        a {
          font-weight: 700;
          color: #444;
        }
      }
    }
  }
}

@media only screen and (min-width:1024px) {
  .pageEl {
    &.sn-agg-time {
      .item {
        .dateAuthor {
          // padding-top: 10px;
          // @media only screen and (max-width: 768px) {
          //   display: none;
          }
          .newsDate,
          .newsAuthor {
            // display: none;
          }
        }
        h4 {
          font-size: 24px !important;
          // font-weight: 700;
          // line-height: 1.3em;
          // color: #444;
          a {
            // font-weight: 700;
            // color: #444;
          }
        }
			}
	}
	#homePage .pageEl.one-up .pageElement.aggregatorElement.newsAggregatorElement {
			margin-top:0
	}

}
//
@media only screen and (max-width:1024px) {
  .user_mode .one-up .condensed .details{
    padding-top: 15px;
    float: left;
    clear: both;
  }
}
.user_mode{
  .custom-news-hide-1 .condensed:nth-of-type(1) {
    display: none !important;
  }
  .custom-news-hide-1.two-up .newsAggregatorElement .item.even {
    padding-right: 10px;
    padding-left: 0;
  }
  .custom-news-hide-1.two-up .newsAggregatorElement .item.odd {
    padding-left: 10px;
    padding-right: 0;
  }
  .custom-news-hide-2 .condensed:nth-of-type(-n+2) {
    display: none !important;
  }
  .custom-news-hide-3 .condensed:nth-of-type(-n+3) {
    display: none !important;
  }
  .custom-news-hide-3.sn-agg-time .condensed:nth-of-type(4){
    padding-top: 0;
  }
}
.edit_mode{
  .custom-news-hide-0,
  .custom-news-hide-1,
  .custom-news-hide-2,
  .custom-news-hide-3{
    .condensed .newsAuthor,
    .condensed .newsDate {
      display: block !important;
    }
  }
  .custom-news-hide-1{
    .condensed:nth-of-type(1):before {
      content: "This will not show in User Mode";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.68);
      font-weight: bold;
      text-shadow: 0px 0px 5px white;
      font-size: 20px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.47);
      pointer-events: none;
    }
    .condensed:nth-of-type(1) {
      position: relative;
    }
  }
  .custom-news-hide-2{
    .condensed:nth-of-type(-n+2):before {
      content: "This will not show in User Mode";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.68);
      font-weight: bold;
      text-shadow: 0px 0px 5px white;
      font-size: 20px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.47);
      pointer-events: none;
    }

    .condensed:nth-of-type(-n+2) {
      position: relative;
    }
  }
  .custom-news-hide-3{
    .condensed:nth-of-type(-n+3):before {
      content: "This will not show in User Mode";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.68);
      font-weight: bold;
      text-shadow: 0px 0px 5px white;
      font-size: 20px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.47);
      pointer-events: none;
    }

    .condensed:nth-of-type(-n+3) {
      position: relative;
    }
  }
}