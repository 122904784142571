.cta-2x,
.cta-3x {
	//width: calc(100% + 10px);
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1;
	}
	.pageElement {
		margin: 0;
		position: relative;
		.layoutContainer {
			.column {
				padding: 0;
				margin-bottom: 10px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
	.sn-call-to-action {
		height: 0 !important;
		padding: 0;
		padding-bottom: calc(100% / 2);
		background-color: #333;
		&:before,
		&:after {
			display: none;
		}
		.sn-call-to-action-overlay {
			padding: 0;
		}
		.sn-call-to-action-title {
			font-size: 24px;
			margin: 0;
		}
		.sn-call-to-action-subtitle {
			font-size: 24px;
			font-weight: 700;
			line-height: 1;
		}
		img {
			opacity: .3;
		}
	}
}

.cta-3x {
	.sn-call-to-action {
		padding-bottom: calc(100% / 2);
		.sn-call-to-action-title {
			font-size: 24px;
		}
		.sn-call-to-action-subtitle {
			font-size: 24px;
		}
	}
}

@media only screen and (min-width: 48em) {
	// Tablet (768px)
}

@media only screen and (min-width: 64em) {
	// Desktop (1024px)
	.cta-2x,
	.cta-3x {
		margin-left: -5px;
		width: calc(100% + 10px);
		.pageElement {

			.layoutContainer {
				.column {
					padding-left: 5px;
					padding-right: 5px;
					margin-bottom: 0;
				}
			}
		}
	}
	.cta-2x {
		.sn-call-to-action {
			padding-bottom: calc(100% / 3);
		}
	}
}