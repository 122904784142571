// Displays mobile tabs on two lines 
// Add class .tabs-two-lines to parent container or parent element

@media only screen and (max-width: 400px) {
	.tabs-two-lines {
		.tabbedElement ul.contentTabs li {
			min-width: 25%;
			border: 1px solid #ccc;
			border-bottom: 1px solid rgba(200, 200, 200, 0);
			background: #eee;
		}
		.tabbedElement ul.contentTabs li.selected {
			min-width: 25%;
			border: 1px solid #585858;
			background: #fff;
		}
		 .tabbedElement ul.contentTabs li.selected:after {
			display: none;
		}
		 .tabbedElement ul.contentTabs li span a {
			background: none;
			border: 0;
		}
	}
}