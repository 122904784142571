//specific overrides added here
//MN Girls Softball Hub #ngin1669
#ngin1669 {
	.signup-link .linkElement h4 a:before {
		content: 'MN Softball Hub';
	}
	.sn-link-small {
		h4 {
			font-size: 12px;
			@media only screen and (max-width: 360px) {
				font-size: 10px !important;
			}
		}
	}
}

.user_mode {
	&.sportsPage {
		#TeamStatsDiv {
			.pageElement {
				#team-sm-baseball_team_scoring {
					display: none;
				}
			}
		}
	}
}