//Under Contruction Splash
//make 1956 a variable...
// html#ngin1957 body.user_mode{
//   .site-background, #accountNav, #topNav, #siteHeader, #siteContainer, .snFooterContainer{
//     opacity: 0 !important;
//   }
// }
// html#ngin1957 body.user_mode.logged_in, html#ngin1957 body.edit_mode.logged_in{
//   .site-background, #accountNav, #topNav, #siteHeader, #siteContainer, .snFooterContainer{
//     opacity: 1 !important;
//   }
// }
#splash-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 9999999;
    margin: auto;
    left: 0;
    right: 0;
    background-color: $accent-color;
    .splash-layout{
      background-color: transparent;
      position: fixed;
      width: 100%;
      top: 20%;
      z-index: 9999999;
      margin: auto;
      left: 0;
      right: 0;
      max-width: 820px;
      //overflow-y: scroll;
      text-align: left;
      padding: 0px 20px;
      h1, h2, p, a{
        text-align: left;
      }
      > img{
        margin: auto;
        max-width: 120px;
        width: 100%;
        padding-bottom: 5px;
      }
      h1{
        font:300 75px/1em $fontThree;
        color:#fff;
        margin: auto;
        padding-bottom: 40px;
        @include font-smoothing;
      }
      h2{
        color: #fff;
        font: normal 18px/1.8em $fontOne;
        padding-bottom: 45px;
      }
      p{
        font:600 25px/1em $fontOne;
        color:#fff;
        padding-bottom: 0px;
        margin-bottom: 15px;
      }
      a{
        color: #fff;
        padding: 15px 20px;
        border: 1px solid #fff;
        font: normal 27px/1.5em $fontOne;
        display: inline-block;
        max-width: 400px;
        width: 100%;
        @media only screen and (max-width: 768px){
          font-size: 22px;
        }
        span{
          display: inline-block;
        }
        img{
          max-width: 300px;
          width: 70%;
          margin-left: 4px;
        }
      }
      @media (max-width: 768px) {
        h1{
          font-size: 36px;
        }
      }
    }
}
