$team-color-home: #222;
$team-color-away: #ccc;
$sports-page-border-color-light: #efefef;
$sports-page-border-color-medium: #cdcdcd;
.lacrosse {
	#GameShowContent {
		h3 {
			font-family: PopulistText;
			font-size: 30px;
		}
		.comment-content {
			h3 {
				font-size: 1em;
			}
			p {
				font-size: 16px;
				margin-bottom: 1rem;
			}
			.comment-links a {
				font-size: 16px;
				text-decoration: underline;
			}
		}
		.loginRequired {
			padding-left: 10px;
		}
		.player_stat-leader {
			padding: 0;
			margin-bottom: 20px;
			&>div:first-child {
				border: 1px solid #ccc;
				padding-bottom: 10px;
			}
			.player_photo {
				top: 10px;
				left: 10px;
			}
			.player_no {
				display: none;
			}
			.player_name {
				font-size: 14px;
				padding-left: 5px;
				max-width: initial;
			}
			.awayTeam li.player_name {
				color: #000;
			}
		}
		ul.StatWidgetTabs li {
			padding-left: 0;
		}
		.StatWidget .scoring_info.awayTeam {
			color: #000;
		}
		.StatWidgetContainer.header {
			background: transparent;
			color: #444;
			border-bottom: 1px solid $sports-page-border-color-medium;
			padding-bottom: 0;
			text-indent: 0;
		}
	}
	.GameShowDataContainer {
		padding: 20px 0 0 0;
		.StatWidgetGroup {
			margin: 0;
		}
		.StatWidgetContainer {
			padding: 20px 0 0 0;
			.StatWidget {
				margin: 0;
				span.player_stat-leader-header {
					font-size: 18px;
					text-transform: capitalize;
				}
				ul.scoring_summary {
					.interval_row {
						ul li:first-child {
							font-weight: 700;
						}
					}
				}
				.stat_category,
				.stat_category span {
					text-align: left !important;
					font-size: 18px;
					color: #222;
					font-weight: 600;
					margin-bottom: 5px;
				}
				.play_details {
					font-weight: bold;
				}
				.game_details {
					margin-left: 10px;
					h3 {
						font-family: "Open Sans", sans-serif !important;
						font-size: 18px !important;
						color: #444 !important;
					}
					li {
						font-size: 14px !important;
						color: #444 !important;
						padding: 0 !important;
					}
				}
			}
		}
		&.game_summary .player_stat-leader .player_statbox {
			min-height: 70px;
			margin: 0 0 0 80px;
			span {
				text-align: left;
			}
			span.statbox_stat {
				font-size: 38px;
				padding-top: 13px;
				line-height: 1;
				color: #474747;
				font-weight: 900;
				height: initial;
			}
			span.statbox_category {
				line-height: 2;
				span {
					font-size: 12px;
					color: #474747;
				}
			}
		}
	}
	.sport-tools {
		ul.pill-buttons li {
			a {
				span {
					white-space: nowrap;
				}
			}
		}
	}
	#GameShowScoreboard {
		max-width: 480px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 15px;
		z-index: 1;
		.team_info {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			.score_area {
				order: 2;
				white-space: nowrap;
				top: 0;
				.score_frame {
					display: flex;
					width: auto;
					.team_logo {
						a {
							&.teamLogo-extra_large {
								background-size: 100% !important auto !important;
								min-height: 100px;
							}
						}
					}
				}
			}
			.team_name {
				order: 1;
				line-height: 1;
				height: auto;
				top: 0;
			}
		}
		.team_score {
			height: 100px;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.away_team {
			left: 0;
			top: 0;
			.team_name {
				font-family: "Open Sans", sans-serif;
				color: #222;
				font-weight: 700;
				text-transform: none;
				left: auto;
				text-align: center;
				width: 100%;
			}
			.team_info {
				border: none;
				background-color: transparent;
				background-image: none;
				width: 100%;
				.team_name {
					height: 100px;
					align-items: center;
					display: flex;
				}
			}
			.team_logo {
				margin: initial;
			}
			.score_frame {
				border: none;
				background-color: transparent;
				background-image: none;
			}
			.score_area {
				border: none;
				background-color: transparent;
				background-image: none;
				background: transparent;
				left: auto;
				margin: 0 auto;
				top: 0;
				height: 100px;
			}
		}
		.home_team {
			right: 0;
			top: 0;
			.team_name {
				font-family: "Open Sans", sans-serif;
				color: $team-color-home;
				font-weight: 700;
				text-transform: none;
				left: auto;
				text-align: center;
				width: 100%;
			}
			.team_info {
				.team_name {
					order: 1;
					height: 100px;
					align-items: center;
					display: flex;
				}
				border: none;
				background-color: transparent;
				background-image: none;
				width: 100%;
			}
			.team_logo {
				margin: initial;
			}
			.score_frame {
				border: none;
				background-color: transparent;
				background-image: none;
			}
			.score_area {
				border: none;
				background-color: transparent;
				background-image: none;
				background: transparent;
				left: auto;
				margin: 0 auto;
				top: 0;
				height: 100px;
			}
		}
		.team_score {
			font-family: "Open Sans", sans-serif;
			color: #242424;
			font-weight: 700;
			text-transform: none;
			font-size: 36px;
			color: #444;
			text-shadow: none;
			&.home_team_score {
				order: 2;
			}
		}
		.team_logo {
			max-width: 120px;
			a {
				max-width: 120px;
			}
		}
		.field_position {
			background-color: transparent;
			background-image: none;
			display: none;
		}
		.line_score {
			height: auto;
			.line_score-table {
				background-color: transparent;
				background-image: none;
			}
			min-width: calc(100% / 3);
			div {
				div {
					float: none;
					height: auto;
				}
			}
		}
		.left-game_clock {
			border: none;
			background-color: transparent;
			background-image: none;
		}
		.right-game_clock {
			border: none;
			background-color: transparent;
			background-image: none;
		}
		.game_clock {
			border: none;
			background-color: transparent;
			background-image: none;
			ul.game_clock-info {
				li {
					font-family: inherit;
					font-size: 13px;
					color: #222;
					text-shadow: none;
					text-transform: capitalize;
				}
				li.status {
					background: none;
					border: none;
					span {
						font-size: 13px;
						font-weight: 700;
						color: #222;
						line-height: 26px;
					}
				}
			}
		}
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		height:auto;
		>* {
			float: none;
			position: relative;
			display: block;
			width: 100%;
			left: auto;
			right: 0;
			margin: 0;
			>* {
				float: none;
				position: relative;
				display: block;
				width: auto;
				left: auto;
				right: 0;
				margin: 0;
			}
		}
		.boxscore {
			display: none;
		}
		.game_clock_container {
			display: block;
			padding: 0;
			position: relative;
			top: 0;
			left: 0;
			max-width: 100%;
			width: 100%;
			.left-game-clock,
			.right-game-clock {
				display: none;
			}
			.game_clock {
				float: none;
				margin: 0 auto;
				.centeredContainer {
					float: none;
					left: 0;
					.centeredContent {
						position: initial;
						float: none;
						margin: 0 auto;
						left: auto;
						ul.game_clock-info {
							display: flex;
							justify-content: center;
						}
					}
				}
			}
		}
		.line_score-table {
			width: 100%;
		}
	}
	.line_score {
		border: none;
		background-color: transparent;
		background-image: none;
		.centeredContent {
			left: auto;
		}
		.edge-l {
			border: none;
			background-color: transparent;
			background-image: none;
			display: none;
		}
		.edge-r {
			border: none;
			background-color: transparent;
			background-image: none;
			display: none;
		}
		.line_score-table {
			height: auto;
			border-collapse: collapse;
			th {
				text-transform: none;
				font-family: inherit;
				font-size: 16px;
				border-top: 1px solid #cdcdcd;
				border-bottom: 1px solid #cdcdcd;
				padding: 7px;
				color: #444;
				font-size: 14px;
				background-color: #eee;
				padding: 3px;
			}
			td {
				text-transform: none;
				font-family: inherit;
				font-size: 16px;
				border-top: 1px solid #cdcdcd;
				border-bottom: 1px solid #cdcdcd;
				padding: 7px;
				color: #444;
			}
			tr {
				border-right: 1px solid #cdcdcd;
				border-left: 1px solid #cdcdcd;
				&:nth-child(even) {
					background-color: #eee;
				}
			}
			a {
				color: #222;
			}
		}
	}
	#LiveGame {
		display: none;
	}
	.field_position {
		display: none;
	}
	#GameShowContent {
		.GameShowDataContainer.game_summary {
			ul.team_stats-list li ul li.stat-bar_graph .awayTeam span {
				border-right: 0;
			}
		}
		li.tab_menu-tab {
			a {
				font-size: 11px;
				color: #777;
				padding: 8px 10px;
				margin-right: 1px;
				border: 1px solid #eee;
			}
			border: none;
		}
		li.tab_menu-tab.selected {
			a {
				color: #000;
				background: transparent;
			}
		}
		.scoring_info {
			padding: 10px;
		}
		.homeTeam {
			background-color: $team-color-home;
		}
		.awayTeam {
			background-color: $team-color-away;
		}
		.rapidContentGeneral {
			width: 100%;
			position: relative;
			display: block;
		}
		> * {
			box-sizing: border-box; //content-box; // @fix for platform update
			border-radius: 0;
			border: 0;
		}
		.StatWidgetContainer {
			&.team_stat-comparison {
				background: none;
				.stat-bar_graph {
					display: block;
					.homeTeam {
						//color: #fff;
						background-image: none;
						background-color: $team-color-home;
						border-radius: 0;
						padding: 0px 10px 6px;
						box-sizing: border-box;
						height: 34px;
					}
					.awayTeam {
						background-image: none;
						background-color: $team-color-away;
						border-radius: 0;
						padding: 0px 10px 6px;
						box-sizing: border-box;
						height: 34px;
					}
				}
			}
		}
		ul.StatWidgetTabs {
			li {
				span {
					display: none;
				}
			}
			li.selected {
				box-shadow: none;
			}
			li.live_game_sheet {
				border: 1px solid #eaeaea;
				background: transparent;
				padding: 5px 10px;
				font-size: 12px;
				box-shadow: none;
				border-radius: 0;
				text-shadow: none;
				&:hover {
					text-shadow: none;
				}
			}
		}
		.tab_menu {
			width: 100% !important;
		}
		ul.tab_menu-list {
			// width: 100%; // @fix plat
			// padding: 0px; // @fix plat
		}
		.comment {
			padding: 20px 2%;
		}
	}
	.GameShowDataColumn {
		display: block;
		width: 100%;
		position: relative;
		top: 0;
		bottom: 0;
	}
	div#CommentStreamContainer {
		display: block;
		width: 100%;
		position: relative;
		padding-top: 20px;
	}
	.player_stat-leader {
		.player_photo {
			span.player_photo_image {
				img.portrait {
					width: 100%;
					height: auto;
					margin: 0;
				}
			}
		}
	}
	.GameShowDataContainer.game_summary {
		.player_stat-leader {
			.player_photo {
				span.player_photo_image {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	a#comment_link_0 {
		display: block;
	}
	.gameShowColumnHeader.commentsDiv {
		display: none;
	}
	.commentThreadElement-container {
		>h3 {
			padding: 0px 10px;
		}
		margin-top: 4px;
	}
	.pageElement.commentThreadElement {
		margin: 0;
	}
	.comment-form {
		fieldset {
			.submit {
				float: left;
				margin-left: 10px;
			}
		}
		textarea {
			margin: 0 2%;
			width: 94%;
			padding: 1%;
		}
	}
	div#commentRequest {
		margin-left: 10px;
		p {
			margin-bottom: 10px;
		}
	}
	.gameShowColumnContent {
		overflow: hidden;
	}
}

// Player Stats 
.lacrosse {
	#GameShowContent {
		.game_player_stats {
			.StatWidgetContainer {
				flex-flow: column;
				.table_container {
					border: 1px solid $sports-page-border-color-light;
					margin-bottom: 20px;
				}
				.player_stats-list {
					border-collapse: collapse;
					border: 1px solid $sports-page-border-color-medium;
					thead {
						border: 1px solid $sports-page-border-color-medium;
					}
				}
				.player_stats-list-header li {
					padding-right: 7px;
				}
			}
		}
	}
}

// Player Stats
.lacrosse {
	#GameShowContent {
		ul.player_stats-list-header.awayTeam li h3,
		ul.player_stats-list-header.homeTeam li h3 {
			font-family: "Open Sans", sans-serif;
			font-weight: 700;
			font-size: 16px;
		}
		.dataTable tfoot:after,
		.player_stats-list tfoot:after {
			display: none;
		}
	}
}

// Team Stats 
.lacrosse {
	#GameShowContent {
		.StatWidget * {
			box-sizing: border-box !important;
		}
		#tab_gs3_game_stats_content {
			.team_stats-list-header {
				h3 {
					font-family: "Open Sans", sans-serif;
					font-weight: 700;
					font-size: 18px;
				}
			}
		}
	}
}

// Play by Play 
.lacrosse {
	#PBP .pbp-team-header .awayTeam {
		color: #222;
		font-weight: 700;
	}
}

@media only screen and (min-width: 1155px) {
	.lacrosse {
		#GameShowScoreboard {
			flex-flow: row nowrap;
			align-items: center;
			max-width: 100%;
			.home_team .team_info .team_name {
				order: 2;
			}
			.home_team .team_logo {
				order: 2;
			}
			.team_score {
				&.home_team_score {
					order: 1;
				}
			}
			.game_clock_container {
				position: absolute;
				top: -10px;
			}
		}
		#GameShowContent {
			.StatWidgetContainer {
				display: flex;
				justify-content: space-between;
			}
			.StatWidgetColumn.width50 {
				width: 49.5% !important;
			}
			.game_player_stats {
				.StatWidgetContainer {
					flex-flow: column;
				}
			}
		}
	}
}

// @fix platfom
#gameTotal{
	@include clearfix;
}