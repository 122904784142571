// 1. Variable defaults
// 2. Base Styles

// Variables
$page-element-margin: 15px !default;

// Base Styles
.pageEl {
  @extend %clearfix;
  > .pageElement{
    float: left;
  }
}
.pageElement {
  width: 100%;
  margin: $page-element-margin 0;
  clear: both;
  .pageElement{
    // unsure why this is neccessary, but including it in case
    margin-left: 0;
    margin-right: 0;
  }
}
.pageEl .pageElement {
  > h3 {
    display: inline-block;
    color: $accent-color;
    font-size: 32px;
    font-family: $fontThree;
  }
}
.layoutContainer .col-md-4{
  .pageEl .pageElement {
    > h3 {
      font-size: 24px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .layoutContainer{
    .pageEl .pageElement {
      > h3 {
        font-size: 24px;
      }
    }
  }
}
