[id^="widget_standings_widget_"],
#tab_division_standings_content {
  .widgetHierarchy{
    border: 1px solid #ccc;
  }
  .sportTableLabel {
    @include sport-table-label;
  }
  ul {
    ul {
      li{
        overflow-x: auto;
        border: 1px solid #ccc;
        margin-top: 15px;
        margin-bottom: 15px
      }
      padding-top: 2px;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      // &:before {
      //   background: $bodyCopyColorThree;
      //   content: '';
      //   height: calc(100% - 4px);
      //   left: 2px;
      //   position: absolute;
      //   width: 3px;
      // }
      h4.teamName:before {
        background: $bodyCopyColorThree;
        content: '';
        height: 3px;
        left: -22px;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        width: 20px;
      }
      @media (max-width: 768px) {
        padding-left: 4px;
        padding-right: 4px;
        h4.teamName:before {
          display: none;
        }
      }
    }
  }
  th:not(.name) {
    text-align: left;
  }
}

tr[id^="standing_"] {
  td.name {
    text-align: left;
    width: 300px;
  }
}

tr[id^="standing_"] .teamLogo-tiny {
  background: #fff;
  margin-right: 10px;
  position: relative;
  &:after {
    background: $accent_color;
    content: '';
    height: 100%;
    position: absolute;
    right: -5px;
    width: 3px;
  }
}
