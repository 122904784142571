/*control type arrow css*/
.control-type-parent {
  position: relative;
}
.control-type-arrow .control-right,
.control-type-arrow .control-left {
  width:44px;
  height:100%;
  top:0;
  left:0;
  position:absolute;
  z-index:999;
  opacity: 1;
  transition: opacity 300ms;
  background: -moz-linear-gradient(left, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.3) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0,0,0,0.3) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d494949', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.control-type-arrow .control-right {
  left:initial;
  right:0;
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#4d494949',GradientType=1 ); /* IE6-9 */
}
.control-type-arrow.control-position-start .control-left{
  opacity: 0;
}
.control-type-arrow.control-position-end .control-right{
  opacity: 0;
}
.control-right i.fa,
.control-left i.fa {
  color: #fff;
  font-size: 25px;
}
/*star tribune overrides*/
.control-type-arrow.sn-scroll-arrow:before {
  content:none;
}
