/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement{
  h4{
    font-family: $fontOne;
    font-size: rem(14);
    font-weight: 600;
    text-transform: none;
    a{
      background-color: $accent-color;
      box-shadow: none;
      display: block;
      color: #fff;
      padding: 10px 20px;
      margin-bottom: 0px;
      line-height: 1.3;
      text-decoration: none;
      transition: background-color .2s;
      text-align: center;
      font-weight: bold;

      @media only screen and (max-width: 768px){ padding: 5px 20px; }

      &:hover{
        background-color: #000;
      }
    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
    &:after{
      color: #fff;
      content: "\f061"; // Right Arrow
      font-family: FontAwesome;
      display:inline-block;
      line-height: .8em;
      margin:0 0 0 6px;
      padding:0;
      position: relative;
      transition: color .2s;
      display: none;
    }
    &:hover:after {
      color: #fff;
    }
  }
  .emailLink {
    &:after{
      content: "\f003"; // envelope-o
    }
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
  .facebook-link &,
  .twitter-link &,{
    margin: 10px 0px;
  }
  // Custom Social Links
  .facebook-link &,
  .twitter-link &,
  .signup-link & {

    h4 a {
      font-size: 20px;
      font-family: 'PopulistText';
      -webkit-font-smoothing: antialiased;
      letter-spacing: .5px;
      padding: 10px 75px 10px 10px !important;
      transition: all $transition-standard;
      text-align: left;

      &:before {
        content: '';
        font-weight: 100;
        font-size: 13px;
        display: block;
        font-family: $fontOne;
        position: relative;
        display: block;
        margin-left: -4px;
      }

      &:after {
        color: #ffffff;
        font-family: FontAwesome;
        position: absolute;
        right: 0;
        height: calc(100% - 20px);
        top: 0;
        font-size: 36px;
        line-height: 42px;
        margin: 10px 0;
        padding: 0 15px;
        border-left: solid 1px #ffffff;
        display: block;
      }

    }

  }
  .sn-link-small &{
    margin: 0px !important;
    h4{
      font-size: 15px;
      a{
        padding: 10px 5px!important;
        font-weight:700;
      }
    }
  }
  .facebook-link & {

    h4 a {
      background-color: #406ca6;

      &:before { content: 'Like Us On Facebook'; }

      &:after {
        content: '\f09a';
        padding: 0 22px 0 21px;
      }

      &:hover { background-color: darken(#406ca6, 5%); }

    }

  }


  .twitter-link & {

    h4 a {
      background-color: #0cb8e9;

      &:before { content: 'Follow Us On Twitter'; }

      &:after { content: '\f099'; }

      &:hover { background-color: darken(#0cb8e9, 5%); }

    }

  }

  .signup-link & {

    padding-top: 0px !important;
    padding-bottom: 15px !important;
    margin-top: 5px !important;
    @media only screen and (max-width: 768px){
      margin-top: 7px !important;
      padding-left: 0px !important;
    }

    h4 a {
      background-color: $accent-color;

      &:before { content: 'MN Hockey Hub'; }

      &:after {
        content: '';
        background-image: url('https://assets.ngin.com/site_files/268/_site/images/site/logos/icon-team-product.svg');
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 35px;

      }

      &:hover { background-color: darken($accent-color, 5%); }

    }

  }

}
@media only screen and (max-width: 1185px) {
  .pageEl.sn-link-small{
    &.sn-two-third, &.sn-one-third, &.sn-one-half, &.sn-one-fifth, &.sn-two-fifth, &.sn-three-fifth {
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 1024px) {
  $fifth: 20%;
  .pageEl.sn-link-small{
    &.sn-two-fifth{
      width: $fifth*2 !important;
    }
    &.sn-three-fifth {
      width: $fifth*3 !important;
    }
  }
}
@media only screen and (max-width: 768px) {
  .pageEl.sn-link-small{
    &.sn-two-fifth, &.sn-three-fifth {
        .linkElement{
          padding: 0px !important;
          padding-right: 5px !important;
          h4{
            font-size: 15px;
            a{
              //font-weight: bold;
            }
          }
        }
    }
  }
}
@media only screen and (max-width: 360px) {
  .pageEl.sn-link-small{
    &.sn-two-fifth, &.sn-three-fifth {
        .linkElement{
          h4{
            font-size: 13.5px;
            a{
              //font-weight: bold;
            }
          }
        }
    }
  }
}
@media only screen and (max-width: 320px) {
  .pageEl.sn-link-small{
    &.sn-two-fifth, &.sn-three-fifth {
        .linkElement{
          h4{
            font-size: 12px;
            a{
              //font-weight: bold;
            }
          }
        }
    }
  }
}
@media only screen and (max-width: 600px) {
  .pageEl.sn-link-small{
    &.sn-two-fifth, &.sn-three-fifth {
        .linkElement h4{
          //font-size: 14px;
        }
    }
  }
  .pageEl.sn-group .tabContainer .layoutContainer .column .pageEl .linkElement{
    padding-left: 0px !important; // from https://app21.workamajig.com/Platinum/?aid=projects.todo.edit&k=136311&io=%7B%7D
    // padding: 0px !important;
    // padding-right: 5px !important;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      font-weight: bold;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}
