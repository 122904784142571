/* Feed Reader */

// Feed reader
.feeder{

  h4 {
    font-size: 16px;
    text-transform: none;

    a {
      color: #444444;
      font-weight: 600;
      line-height: normal;
      transition: all $transition-standard;
      letter-spacing: -0.5px;

      &:hover {
        color: #444;
        text-decoration: underline;
      }

    }

  }

  > h3{
    @include special-header;
    @include word-wrap();
  }
  > div:nth-of-type(1){
    > div{
      border-bottom: solid 1px #cccccc !important;
      border-top-width: 0 !important;
      border-image: none;
      padding: 15px 0;

      &:last-of-type { border-bottom: none !important; }

      > ul li:last-child{
        > p:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .even, .odd{

    ul li:last-of-type { display: none; }

    > ul{
      list-style-type: none;
      ul{
        list-style-type: disc;
        padding-left: .5em;
        margin-bottom: 1em;
      }
      ol{
        margin-bottom: 1em;
      }
      ul li, ol li{
        margin-left: 1em;
      }
    }
  }
  blockquote{
    float: none;
    color: $bodyCopyColorTwo;
    font-size: initial;
    width: initial;
    padding: 0 1em;
    border-left: 2px solid #EEE;
    margin: 1em;
    &:before, &:after{display: none;}
  }
  img{
    max-width: 100%;
  }
  table{
    max-width: 100%;
    overflow-x: scroll;
    display: block;
  }

  .extendedOptions { display: none; }

}
