//specific overrides added here
//MN Volleyball Hub #ngin1956
#ngin1956 {
  .signup-link .linkElement h4 a:before {
    content:'MN Volleyball Hub';
  }
  .sn-link-small {
    h4{
      font-size: 12px;
      @media only screen and (max-width: 360px){
        font-size: 10px !important;
      }
    }
  }
}
