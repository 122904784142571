/* Variables */
// 1. Imports
// 2. Functions
// 3. Defaults
// 4. Colors
// 5. Mixins
// 6. Animations

//ADDED
$asset-path-for: "https://app-assets1.sportngin.com";

// 1. Colors
$accent-color: #222;
$highlight-color: #eee;
$button-highlight_text: $accent-color;
$background-color: #fff;
$link-color: $accent-color;
//$link-color: #006341; forest green no longer used
$hover-color: #eee;

// layout
$page-element-margin: 15px;


$link-color-opacity: rgba($link-color,0.7);

// Link colors
$link-color-darken-5: darken($link-color, 5%);
$link-color-darken-10: darken($link-color, 10%);
$link-color-darken-15: darken($link-color, 15%);
$link-color-darken-20: darken($link-color, 20%);
$link-color-darken-25: darken($link-color, 25%);
$link-color-darken-30: darken($link-color, 30%);
$link-color-darken-35: darken($link-color, 35%);
$link-color-darken-40: darken($link-color, 40%);
$link-color-darken-45: darken($link-color, 45%);
$link-color-darken-50: darken($link-color, 50%);

$link-color-lighten-5: lighten($link-color, 5%);
$link-color-lighten-10: lighten($link-color, 10%);
$link-color-lighten-15: lighten($link-color, 15%);
$link-color-lighten-20: lighten($link-color, 20%);
$link-color-lighten-25: lighten($link-color, 25%);
$link-color-lighten-30: lighten($link-color, 30%);
$link-color-lighten-35: lighten($link-color, 35%);
$link-color-lighten-40: lighten($link-color, 40%);
$link-color-lighten-45: lighten($link-color, 45%);
$link-color-lighten-50: lighten($link-color, 50%);

// Background colors
$background-color-darken-5: darken($background-color, 5%);
$background-color-darken-10: darken($background-color, 10%);
$background-color-darken-15: darken($background-color, 15%);
$background-color-darken-20: darken($background-color, 20%);
$background-color-darken-25: darken($background-color, 25%);
$background-color-darken-30: darken($background-color, 30%);
$background-color-darken-35: darken($background-color, 35%);
$background-color-darken-40: darken($background-color, 40%);
$background-color-darken-45: darken($background-color, 45%);
$background-color-darken-50: darken($background-color, 50%);

$background-color-lighten-5: lighten($background-color, 5%);
$background-color-lighten-10: lighten($background-color, 10%);
$background-color-lighten-15: lighten($background-color, 15%);
$background-color-lighten-20: lighten($background-color, 20%);
$background-color-lighten-25: lighten($background-color, 25%);
$background-color-lighten-30: lighten($background-color, 30%);
$background-color-lighten-35: lighten($background-color, 35%);
$background-color-lighten-40: lighten($background-color, 40%);
$background-color-lighten-45: lighten($background-color, 45%);
$background-color-lighten-50: lighten($background-color, 50%);

// Accent colors
$accent-color-darken-5: darken($accent-color, 5%);
$accent-color-darken-10: darken($accent-color, 10%);
$accent-color-darken-15: darken($accent-color, 15%);
$accent-color-darken-20: darken($accent-color, 20%);
$accent-color-darken-25: darken($accent-color, 25%);
$accent-color-darken-30: darken($accent-color, 30%);
$accent-color-darken-35: darken($accent-color, 35%);
$accent-color-darken-40: darken($accent-color, 40%);
$accent-color-darken-45: darken($accent-color, 45%);
$accent-color-darken-50: darken($accent-color, 50%);

$accent-color-lighten-5: lighten($accent-color, 5%);
$accent-color-lighten-10: lighten($accent-color, 10%);
$accent-color-lighten-15: lighten($accent-color, 15%);
$accent-color-lighten-20: lighten($accent-color, 20%);
$accent-color-lighten-25: lighten($accent-color, 25%);
$accent-color-lighten-30: lighten($accent-color, 30%);
$accent-color-lighten-35: lighten($accent-color, 35%);
$accent-color-lighten-40: lighten($accent-color, 40%);
$accent-color-lighten-45: lighten($accent-color, 45%);
$accent-color-lighten-50: lighten($accent-color, 50%);

// Hover colors
$hover-color-darken-5: darken($hover-color, 5%);
$hover-color-darken-10: darken($hover-color, 10%);
$hover-color-darken-15: darken($hover-color, 15%);
$hover-color-darken-20: darken($hover-color, 20%);
$hover-color-darken-25: darken($hover-color, 25%);
$hover-color-darken-30: darken($hover-color, 30%);
$hover-color-darken-35: darken($hover-color, 35%);
$hover-color-darken-40: darken($hover-color, 40%);
$hover-color-darken-45: darken($hover-color, 45%);
$hover-color-darken-50: darken($hover-color, 50%);

$hover-color-lighten-5: lighten($hover-color, 5%);
$hover-color-lighten-10: lighten($hover-color, 10%);
$hover-color-lighten-15: lighten($hover-color, 15%);
$hover-color-lighten-20: lighten($hover-color, 20%);
$hover-color-lighten-25: lighten($hover-color, 25%);
$hover-color-lighten-30: lighten($hover-color, 30%);
$hover-color-lighten-35: lighten($hover-color, 35%);
$hover-color-lighten-40: lighten($hover-color, 40%);
$hover-color-lighten-45: lighten($hover-color, 45%);
$hover-color-lighten-50: lighten($hover-color, 50%);

// Highlight colors
$highlight-color-darken-5: darken($highlight-color, 5%);
$highlight-color-darken-10: darken($highlight-color, 10%);
$highlight-color-darken-15: darken($highlight-color, 15%);
$highlight-color-darken-20: darken($highlight-color, 20%);
$highlight-color-darken-25: darken($highlight-color, 25%);
$highlight-color-darken-30: darken($highlight-color, 30%);
$highlight-color-darken-35: darken($highlight-color, 35%);
$highlight-color-darken-40: darken($highlight-color, 40%);
$highlight-color-darken-45: darken($highlight-color, 45%);
$highlight-color-darken-50: darken($highlight-color, 50%);

$highlight-color-lighten-5: lighten($highlight-color, 5%);
$highlight-color-lighten-10: lighten($highlight-color, 10%);
$highlight-color-lighten-15: lighten($highlight-color, 15%);
$highlight-color-lighten-20: lighten($highlight-color, 20%);
$highlight-color-lighten-25: lighten($highlight-color, 25%);
$highlight-color-lighten-30: lighten($highlight-color, 30%);
$highlight-color-lighten-35: lighten($highlight-color, 35%);
$highlight-color-lighten-40: lighten($highlight-color, 40%);
$highlight-color-lighten-45: lighten($highlight-color, 45%);
$highlight-color-lighten-50: lighten($highlight-color, 50%);

// Button highlight text colors
$button-highlight-text-darken-5: darken($button-highlight_text, 5%);
$button-highlight-text-darken-10: darken($button-highlight_text, 10%);
$button-highlight-text-darken-15: darken($button-highlight_text, 15%);
$button-highlight-text-darken-20: darken($button-highlight_text, 20%);
$button-highlight-text-darken-25: darken($button-highlight_text, 25%);
$button-highlight-text-darken-30: darken($button-highlight_text, 30%);
$button-highlight-text-darken-35: darken($button-highlight_text, 35%);
$button-highlight-text-darken-40: darken($button-highlight_text, 40%);
$button-highlight-text-darken-45: darken($button-highlight_text, 45%);
$button-highlight-text-darken-50: darken($button-highlight_text, 50%);

$button-highlight-text-lighten-5: lighten($button-highlight_text, 5%);
$button-highlight-text-lighten-10: lighten($button-highlight_text, 10%);
$button-highlight-text-lighten-15: lighten($button-highlight_text, 15%);
$button-highlight-text-lighten-20: lighten($button-highlight_text, 20%);
$button-highlight-text-lighten-25: lighten($button-highlight_text, 25%);
$button-highlight-text-lighten-30: lighten($button-highlight_text, 30%);
$button-highlight-text-lighten-35: lighten($button-highlight_text, 35%);
$button-highlight-text-lighten-40: lighten($button-highlight_text, 40%);
$button-highlight-text-lighten-45: lighten($button-highlight_text, 45%);
$button-highlight-text-lighten-50: lighten($button-highlight_text, 50%);


// 1. Imports
//

// 2. Functions
@function em($target) {
  @return $target * 1px;
}
@function rem($target) {
  @return $target * 1px;
}

// 3. Defaults
$theme: custom;
$siteNumber: 268;

// Open Sans Regular - 400
@font-face {
 font-family: 'Open Sans';
 font-style: normal;
 font-weight: 400;
 src: local('Open Sans Regular'), local('Open Sans-Regular'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/OpenSans-Reg.woff') format('woff');
}
@font-face {
 font-family: 'Open Sans';
 font-style: italic;
 font-weight: 400;
 src: local('Open Sans Italic'), local('Open Sans-Italic'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/OpenSans-Ita.woff') format('woff');
}
// Open Sans Semibold  - 600
@font-face {
 font-family: 'Open Sans';
 font-style: normal;
 font-weight: 600;
 src: local('Open Sans Semibold'), local('Open Sans-Semibold'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/OpenSans-Smb.woff') format('woff');
}
// Open Sans Bold  - 700
@font-face {
 font-family: 'Open Sans';
 font-style: normal;
 font-weight: 700;
 src: local('Open Sans Bold'), local('Open Sans-Bold'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/OpenSans-Bol.woff') format('woff');
}
// Open Sans Extra Bold  - 900
@font-face {
 font-family: 'Open Sans';
 font-style: normal;
 font-weight: 900;
 src: local('Open Sans Extrabold'), local('Open Extra-bold'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/OpenSans-Exb.woff') format('woff');
}
// Populist Text Light - 300
@font-face {
 font-family: 'PopulistText';
 font-style: normal;
 font-weight: 300;
 src: local('PopulistText Light'), local('PopulistText-Light'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/PopulistText-Lig.woff') format('woff');
}
// Populist Text Regular - 400
@font-face {
 font-family: 'PopulistText';
 font-style: normal;
 font-weight: 400;
 src: local('PopulistText Regular'), local('PopulistText-Regular'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/PopulistText-Reg.woff') format('woff');
}
// Populist Text Black - 900
@font-face {
 font-family: 'PopulistText';
 font-style: normal;
 font-weight: 900;
 src: local('PopulistText Black'), local('PopulistText-Black'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/PopulistText-Bla.woff') format('woff');
}
// Lato Regular - 400
@font-face {
 font-family: 'Lato';
 font-style: normal;
 font-weight: 400;
 src: local('Lato Regular'), local('Lato-Regular'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/Lato-Reg.woff') format('woff');
}
// Lato Bold - 700
@font-face {
 font-family: 'Lato';
 font-style: normal;
 font-weight: 700;
 src: local('Lato Bold'), local('Lato-Bold'), url('https://s3.amazonaws.com/assets.ngin.com/site_files/268/_site/images/site/fonts/Lato-Bol.woff') format('woff');
}

$fontOne: "Open Sans", sans-serif;
$fontTwo: "Open Sans", sans-serif;
$fontThree: "PopulistText", sans-serif;
$fontFour: "Lato", sans-serif;
$page-heading-font: 300 52px/1.5em $fontThree;
$base-font-size: 16;
$base-line-height: 1.5;
$base-font-weight: 400;

$transition-standard: .2s ease-in;
$transition-linear: .2s linear;

//$base-padding: rem(25);
$base-padding: 20px;
$base-padding-sm: rem(10);
$bootstrap-padding-sm: 10;
$bootstrap-padding-md: 10;

$snAdminBarHeight: 30px;
$site-footer-height: 46px;

$maxWidth:1160px;
$desktop_navHeight:140px;
$desktop_navHeight_slim:120px;
$tablet_navHeight:80px;
$mobile_navHeight:60px;
$top-nav-height:55px;
$top-nav-height-slim:45px;

$bodyCopyColorOne:#222;
$bodyCopyColorTwo:#444;
$bodyCopyColorThree:#666;

$borderColor:#ddd;
$borderWidth:2px;


// 4. Colors
$nav_second_level: $background-color-darken-5;
$nav_third_level: $background-color-darken-10;
$nav_fourth_level: $background-color-darken-15;

$table-body-background: #fff;
$table-cell-spacing: 2px;
$table-header-padding-vertical: 3px;
$table-header-padding-horizontal: 12px;
$table-cell-padding-vertical: 6px;
$table-cell-padding-horizontal: 12px;
$table-sort-icon-size: 14px;
$table-sort-icon-padding: $table-sort-icon-size + 4px;
$table-row-min-height: 30px;


// 5. Mixins

// Page Element Breakpoints
// Mixin for any page element that lives within the bootstrap columns.
// To use pass the pixel value of the breakpoint(s) below 768 that you want to target.
// This will generate the neccessary media queries for all possible column scenarios,
// and will ignore any that don't need to be used.
@mixin page-element-break($break){
  // Map defining column sizes within the "md" bootstrap range
  $columns: (
    9: (($break/.75) + ($bootstrap-padding-md * 2)),
    8: (($break/.66666666666) + ($bootstrap-padding-md * 2)),
    6: (($break/.50) + ($bootstrap-padding-md * 2)),
    4: (($break/.3333333333) + ($bootstrap-padding-md * 2)),
    3: (($break/.25) + ($bootstrap-padding-md * 2))
  );
  // Initial breakpoint (typically within the "sm" bootstrap range)
  // Note: Inital breakpoint also includes the panel padding, so any value you pass into $break will have the panel padding added to it.
  @media only screen and (max-width: (($break + ($bootstrap-padding-sm * 2)) * 1px)){
    @content;
  }
  // Cycle through columns map checking if values are within the "md" range and exporting media queries for those that match
  @each $column, $value in $columns {
    @if $value > 768 and $value < 1025 {
      @media only screen and (min-width: 768px) and (max-width: ($value * 1px)){
        .col-md-#{$column}{
          @content;
        }
      }
    }
    @if $value > 1025 { // Need to find a way to lopp these into one chained selector, not multiple media queries.
      @media only screen and (min-width: 768px){
        .col-md-#{$column}{
          @content;
        }
      }
    }
  }
}

@mixin displayCenter($maxWidth) {
  position: relative;
  display: block;
  margin: auto;
  max-width: $maxWidth;
  width: 100%;
}
@mixin aspect-ratio($ratio-or-width, $height: null) {
  $padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
  &:before { content:''; float: left; padding-bottom: $padding;  }
  &:after  { content:''; display: table; clear: both;  }
}
@mixin move-to-bottom{
  top: initial;
  bottom: 0;
  transform: translateY(-10%);
}
@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@mixin truncate-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
@function dynamic-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter background, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}
@mixin line-pattern($line-color:#aaa,$gap-color:transparent) {
  background: repeating-linear-gradient(-72deg, $line-color 0px, $gap-color 1px, $gap-color 3px, $line-color 4px);
}
@mixin line-pattern-border($height:3, $pos:top, $line-color:#AAA, $gap-color:transparent){
  //border-style: dotted;
  //border-width: 0; // start by knocking out all the borders
  //border-#{$pos}-color: #ddd;
  //border-#{$pos}-width: ($height * 1px) !important;
  //border-image: repeating-linear-gradient(-72deg, $line-color 0px, $gap-color 1px, $gap-color 3px, $line-color 4px) $height $height;
}
// @mixin special-header {
//   display: inline-block;
//   width: auto;
//   max-width: calc(100% - 9px); // Ensures that the box shadow shows at small sizes
//   height: auto;
//   padding: 10px 20px 10px 10px;
//   color: #fff;
//   background: $link-color;
//   font-size: 24px;
//   box-shadow:
//     2px 0 #fff,
//     7px 0 $accent-color;
// }
@mixin special-header {
  display: inline-block;
  color: #444444;
  font-size: 24px;
  font-family: 'PopulistText';
}
@mixin content-tab-item() {
  display: inline-block;
  float: left;
  border: 3px solid transparent;
}
@mixin content-tab() {
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: block;
  font-size: .9em;
  font: bold 13px/1.5 $fontOne;
  padding: 10px 14px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  background: #fff;
  color: #222;
  border: 1px solid #ccc;
}
@mixin table {
  // Add transparent spacing between cells using borders
  border-spacing: 0; // Override setting from elsewhere
  //margin-top: $table-cell-spacing;
  // Apply transparent border to right and bottom of every cell and heading
  td, th {
    //background-clip: padding-box !important; // !important is necessary but I don't know why
    //border-color: transparent;
    //border-style: solid;
    //border-width: 0 $table-cell-spacing $table-cell-spacing 0;
    // Don't apply right border to last cell or heading in a row
    &:last-child {
      //border-right: 0;
    }
  }
  tr{
    td{
      background: #eee;
    }
    &.odd{
      td{
        background: #fff;
      }
    }
    &.even{
      td{
        background: #eee;
      }
    }
    &.team-away{
      td{
        background: #fff;
      }
    }
    &.highlight{

      &:nth-of-type(even),
      &:nth-of-type(odd) {
        td {
          background: $accent-color;
          color: #fff;

          a { color: #fff; }
        }

      }

      td{
        // background: #222 !important;
        // color: #fff;
        a{
          // color: #fff;
        }
        &.highlight{
          //
        }
      }
    }
  }
  // Don't apply bottom border to cells in last row (assumes THs are never in last row)
  tr:last-child {
    td {
      border-bottom: 0;
    }
  }

  border-collapse: initial; // Somewhere this is set to default to 'collapse'. Should remove default?
  width: 100%;

  //tbody td { background: $table-body-background; }
}
@mixin table-td {
  font-size: 14px;
  padding: $table-cell-padding-vertical $table-cell-padding-horizontal;
  height: $table-row-min-height; // Acts as min-height on table cells
  text-align: left;
  a{
    color: #444;
  }
}
@mixin table-th {
  height: $table-row-min-height; // Acts as min-height on table cells
  padding: $table-header-padding-vertical $table-header-padding-horizontal;
  background: #eee; // Replace all instances of this with theme color var
  white-space: nowrap;
  border-bottom: 1px solid #ccc;
  text-align: left;
}
@mixin table-th-text {
  color: $accent-color;
  text-transform: capitalize;
  line-height: 1.1;
  font: 600 14px/1.2 $fontOne;
}
@mixin table-team-logo {
  position: relative;
  display: inline-block;
  float: none;
  width: 30px !important;
  vertical-align: middle;
  background-size: 100% auto;
  background-position: 50% 50%;
  margin: 2px 6px;
  border: none;
  height: 30px;
  background-repeat: no-repeat;
}
@mixin button-style{
  background:$accent-color;
  //border-bottom: 3px solid $link-color-darken-20;
  margin-bottom: 5px;
  text-transform:none;
  font-family:$fontOne;
  font-size: 16px;
  transition: border-bottom $transition-standard, background-color $transition-standard;
  -webkit-appearance: none;
  &:hover{
    background:#000;
  }
}
@mixin text-field-style{
  height:28px;
  padding: 5px;
  margin-top: 5px;
  font-family:$fontTwo;
  font-size:.75em;
  background:#fff;
  border: 1px solid $borderColor;
  border-radius: 0;
  -webkit-appearance: none;
  transition: all .1s ease;
  &:hover{
    box-shadow:inset 0 1px 3px $borderColor;
    border: 1px solid $bodyCopyColorTwo;
  }
  &:focus{
    box-shadow:inset 0 1px 3px $borderColor;
    border: 1px solid $link-color;
    outline:0;
  }
}
@mixin dropdown-style{
  height:28px;
  font-family:$fontTwo;
  font-size:.75em;
  background:#fff;
  border: 1px solid $borderColor;
  transition: all .1s ease;
  white-space: normal;
  &:hover{
    box-shadow:0 1px 3px $borderColor;
    border: 1px solid $bodyCopyColorTwo;
  }
  &:focus{
    box-shadow:0 1px 3px $borderColor;
    border: 1px solid $link-color;
    outline:0;
  }
}
@mixin caption-style {
  color: $bodyCopyColorTwo;
  font-size: rem(12);
  font-style: italic;
}
@mixin list-item {
  padding: $base-padding 0;
  @include line-pattern-border();
  &:nth-of-type(1){border:none;}
}
@mixin list-item-collector {
  padding: $base-padding 0;
  @include line-pattern-border();
  //&:nth-of-type(1){border:none;}
}
@mixin link-icon-left {
  position:absolute;
  font-family: FontAwesome;
  font-size:rem(14);
  top:0;
  left:5px;
}
@mixin sport-table-label {
  @include special-header;
  //display: none;
  position: relative;
  $icon-size: 20px;
  $icon-spacing: 10px;

  .teamLogo-tiny {
    left: 14px;
    position: absolute;
    top: 14px;
  }

  * { color: inherit; }

  h4.teamName {
    font-size: 24px;
    margin: 0;
    //padding-left: $icon-size + $icon-spacing;
    padding: $base-padding-sm;
    padding-bottom: 0px;
  }

  .seasonDisplay,
  .expandedView {
    display: block;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }

  .linkText { display: block; }
  .season-separator { display: none; }
}
@mixin sport-table-label-short {
  max-width: 100%;
  padding-left: 10px;

  .teamLogo-tiny { display: none; }

  h4 > * {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@mixin link-with-arrow() {
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
  background: #222;
  padding: 5px 10px;
  font-weight: 400;

  &:after {
    color: #fff;
    content: '\f061'; // fa-arrow-right
    display: inline-block;
    font-family: 'FontAwesome';
    margin: -.1em 0 0 .4em;
    font-size: .8em;
    font-weight: 400;
  }
}
@mixin clearfix{
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin word-wrap() {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
@mixin font-smoothing(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// 6. Animations
@-webkit-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}
@keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}
@-webkit-keyframes fade-in{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//ADMIN
// MIXINS

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


// ICONS
@mixin fa-icon {
  font: normal normal normal 14px/1 FontAwesome;
}
@mixin fa-icon-lg {
  @include fa-icon;
  font-size: 1.33333333em;
}
@mixin fa-icon-2x {
  @include fa-icon;
  font-size: 2em;
}
@mixin fa-icon-3x {
  @include fa-icon;
  font-size: 3em;
}
@mixin fa-icon-4x {
  @include fa-icon;
  font-size: 4em;
}
@mixin fa-icon-5x {
  @include fa-icon;
  font-size: 5em;
}


// FONTS - just guessing until we have Whitney
$sn-font-weight-light: 100;
$sn-font-weight-normal: 300;
$sn-font-weight-medium: 500;
$sn-font-weight-heavy: 700;

$sn-font-size-xs: 10px;
$sn-font-size-sm: 12px;
$sn-font-size-md: 14px;
$sn-font-size-lg: 16px;
$sn-font-size-xl: 18px;

@mixin sn-default-font {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; // temp until we get Whitney installed
}

// SHADOWS


// GRADIENTS

@mixin sn-clickable {
  cursor: pointer;
  background: linear-gradient(white, #f1f3f4);
  border: 1px solid #b9c1c6;
}

@mixin sn-clickable-hover {
  background: linear-gradient(#f1f3f4, white);
  border-color: #808f98;
}

@mixin sn-clickable-active {
  @include sn-clickable-bg-hover;
  box-shadow: 0 0 8px rgba(0,0,0,0.5);
}

@mixin sn-clickable-disabled {
  cursor: default;
  color: #d5dadd;
  background: #ffffff;
  border-color: #d5dadd;
}

//
$account-nav-height-desktop: 44px;
$account-nav-height-mobile: 44px;
$account-nav-background: #222;
$account-nav-color: #FFF;
$account-nav-z-index: 401;
$account-nav-icon-width: 32px;

// STIE TAGLINE
$site-tagline-text-color :#fff;
$site-tagline-color: #222;
$site-tagline-spacing: 15px;
$site-tagline-separator-size: 1px;
$site-tagline-separator: $site-tagline-separator-size solid rgba(255,255,255,0.1);
$site-tagline-height: 40px;
$site-tagline-height-fixed: .01px; // hide it (0px does not animate)

// MAIN NAV
$main-nav-font-size: 18px;
$main-nav-text-color: #222;
$main-nav-selected: #222;
$main-nav-hover-color: #222;
$main-nav-text-disabled: #999;
$main-nav-text-disabled-hover: #CCC;
$main-nav-color: #fff;
$main-nav-spacing: 15px;
$main-nav-separator: 1px solid rgba(0,0,0,0.1);
$main-nav-item-separator: 1px solid rgba(255,255,255,0.2);
$main-nav-height: 40px;
$main-nav-height-fixed: 40px;

// SUB NAV
$sub-nav-font-size: 16px;
$sub-nav-title-color: #666;
$sub-nav-color: #e6e4e4;
$sub-nav-selected: #EEE;
$sub-nav-hover-color: #cccccc;
$sub-nav-text-color: $link-color;
$sub-nav-text-disabled: #AAA;
$sub-nav-text-disabled-hover: #999;
$sub-nav-title-spacing: 15px;
$sub-nav-spacing: 10px;
$sub-nav-separator: none;
$sub-nav-item-separator: 1px solid rgba(255,255,255,0.2);
$sub-nav-height: 40px;
$sub-nav-height-fixed: 35px;

// MAIN/SUB NAV DROPDOWNS
$dropdown-font-size: 16px;
$dropdown-text-color: #fff;
$dropdown-text-disabled: #999;
$dropdown-text-disabled-hover: #CCC;
$dropdown-color: rgba(0,0,0,0.9);
$dropdown-selected: rgba(255,255,255,0.1);
$dropdown-border: 1px solid rgba(255,255,255,0.2);
$dropdown-width: 200px;
$dropdown-spacing: 12px;
$dropdown-item-height: 35px;
$dropdown-shift-horiz: 20px;
$dropdown-shift-vert: 20px;

// SEARCH BOX
$site-search-height: $main-nav-height;
$site-search-spacing: 8px;
$site-search-border-width: 1px;
$site-search-input-font-size: 14px;
$site-search-input-color: #666;
$site-search-input-border-color: rgba(255,255,255,0.1);
$site-search-input-background: rgba(255,255,255,0.8);
$site-search-input-background-focus: rgba(255,255,255,1);
$site-search-submit-color: #222;
$site-search-submit-border-color: rgba(255,255,255,0.1);
$site-search-submit-background: #eee;
$site-search-submit-background-focus: #ddd;
$site-search-submit-font-size: 16px;

// SITE LOGO
$site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height + 3;
$site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 3;

// MISC
$nav-placeholder-height: $site-tagline-height + $main-nav-height + $sub-nav-height + 2 + 31;
$nav-placeholder-height-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 2 + 31 + 17;
$nav-item-before-size: 12px;

// SLIDE NAV CONFIG
$slide-nav-width: 250px;
$slide-nav-tablet-width: 400px;
$slide-nav-font-size: 16px;
$slide-nav-background: #eee;
$slide-nav-background-collapsed: #eee;

$slide-nav-content-spacing: 15px;
$slide-nav-content-background: #222;

$slide-nav-toggle-background: transparent;
$slide-nav-toggle-color: #FFF;
$slide-nav-toggle-background-collapsed: $account-nav-background;
$slide-nav-toggle-color-collapsed: $account-nav-color;

$slide-nav-title-color: #222;
$slide-nav-title-background: #ddd;
$slide-nav-title-border: 1px solid rgba(0,0,0,0.1);
$slide-nav-title-height: 44px;

$slide-nav-back-icon: "\f053";
$slide-nav-back-color: #222;
$slide-nav-back-background: #ddd;
$slide-nav-back-background-hover: #ccc;
$slide-nav-back-border: 1px solid rgba(0,0,0,0.05); // seems to double up when transparent

$slide-nav-forward-icon: "\f054";
$slide-nav-forward-color: #222;
$slide-nav-forward-background: transparent;
$slide-nav-forward-background-hover: #ccc;
$slide-nav-forward-border: 1px solid #ccc; // seems to double up when transparent

$slide-nav-menu-background: transparent;

$slide-nav-item-color: #222;
$slide-nav-item-color-disabled: #999;
$slide-nav-item-background: transparent;
$slide-nav-item-background-hover: #ddd;
$slide-nav-item-background-selected: #ddd;
$slide-nav-item-background-disabled: transparent;
$slide-nav-item-background-disabled-hover: #666;
$slide-nav-item-background-disabled-selected: #666;
$slide-nav-item-border: 1px solid rgba(0,0,0,0.1);
$slide-nav-item-height: 44px;

//New Mixins
@mixin site-tagline-text-header{
  display: inline-block;
  font: 300 20px/1.5em "PopulistText";
  color: #fff;
  padding: 10px;
  background: #222;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include font-smoothing;
  &:before{
    content:'';
    background-image:url('../images/site/logos/star.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    width: 18px;
    height: 23px;
    margin-right: 4px;
    margin-bottom: 0px;
  }
  a{
    color: #fff;
    height: auto;
    font: 300 20px/1.5em "PopulistText";
    padding: 0px;
  }
}
@mixin hideElement {
  //hides the element from view without using display:none. Use for accessibility case.
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  text-indent: -99999px;
}
@mixin scrollBottomOverlay{
  &:before{
    content: '\f078';
    font:normal 1em/70px "FontAwesome";
    color: #fff;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 999;
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(102,102,102,0.65) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(102,102,102,0.65) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(102,102,102,0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6666666',GradientType=0 );
  }
}
@mixin setScroll(){
  height: 350px;
  overflow-x: scroll;
  padding: 0px 15px 50px 15px;
  //@include scrollBottomOverlay;
  &:before{
    bottom: 0px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  @at-root .edit_mode #{&} {
    overflow-x: auto;
    height: auto;
    &:before{
      display: none;
    }
  }
}
@mixin setScrollAlt(){
  height: 350px;
  overflow-x: scroll;
  padding: 0px 15px 50px 15px;
  //@include scrollBottomOverlay;
  &:before{
    bottom: 0px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  // @at-root .edit_mode #{&} {
  //   overflow-x: auto;
  //   height: auto;
  //   &:before{
  //     display: none;
  //   }
  // }
}
@mixin rightScrollArrow(){
  &:before{
    content: '';
    position: absolute;
    right:0px;
    bottom: 0;
    height: 100%;
    width: 40px;
    z-index: 99;
    font:normal 1em/70px "FontAwesome";
    color: #fff;
    text-align: center;
    background-image: url('../images/site/icons/right-arrow-samll.png'), -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(102,102,102,0.65) 100%);
    background-image: url('../images/site/icons/right-arrow-small.png'), -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(102,102,102,0.65) 100%);
    background-image: url('../images/site/icons/right-arrow-small.png'), linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(102,102,102,0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6666666',GradientType=1 );
    background-repeat: no-repeat;
    background-position:center;
  }
}
@mixin keywordSearchOnlyOverride(){
  #PageSearch, #PageSearchBoxMobileNav{
    display: none !important;
  }
  .sn-search-container{
    #GoogleContentSearch{
      display: block !important;
    }
  }
  #GoogleSearchBoxMobileNav{
    display: block !important;
  }
  .sn-search-tabs{
    display: none;
  }
  .theme-search-modal .theme-search-results{
    top: 120px;
  }
  .theme-search-modal .theme-search-box{
    margin: 50px 25px 25px 25px;
    @media only screen and (max-width: 1024px){
      margin: 0px 25px;
    }
  }
  #sn-search-tabs-mobile{
    display: none;
  }
}
