//specific overrides added here
//MN Skiing Hub #ngin2226
#ngin2226 {
  .signup-link .linkElement h4 a:before {
    content:'MN Boys’ Basketball Hub';
  }
  .sn-link-small {
    h4{
      font-size: 12px;
      @media only screen and (max-width: 360px){
        font-size: 10px !important;
      }
    }
  }
  @include keywordSearchOnlyOverride;
}
