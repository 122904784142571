#PreviewStatsDiv .next_game {
  //background-color: #eee;
  color: $accent-color;
  overflow:hidden;
  padding:10px;
}
#RosterPlayerStats {
  position: relative;

  .teamLogo-extra_large,
  .teamLogor-extra_large {
    height: 100px !important;
    background-size: contain;
    width: auto !important;
  }

  .next_game_splits {
    border-bottom:10px solid #333;
    margin-top:0;
    padding:0;
    .NginTableControls {
      background-color:#333;
      color:#FFF;
    }
  }
  .next_game{
    .info {
      padding: 3% 0;
      text-align:center;
      line-height:16px;
      width:36%;
      float:left;
    }
    .header {
      display:block;
      font-size:18px;
      margin-bottom:2px;
    }
    .vs_or_at {
      font-size:30px;
      display:block;
      margin-bottom:20px;
    }
    .time {
      display:block;
      font-size:10px;
      color:#999;
    }
    .team {
      float:left;
      width:32%;
      padding:2%;
    }
    .away_team {
      padding-right:6%;
      text-align:left;
    }
    .home_team {
      float:right;
      padding-left:6%;
      text-align:right;
    }
  }
  .team_logo {
    display: block;
    margin: auto;
  }
  .team_name {
    font-size:18px;
    line-height:20px;
    height:20px;
    margin:10px 0 0;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    font-family: "PopulistText";
    font-weight: 100;
    text-align: center;
  }
  .stat-category-header {
    @include special-header;
    font-family: $fontOne;
    margin-top: 15px;
  }
  .statTable {
    @include line-pattern-border($pos: bottom);

    .last {
      td {
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
  .goToLink {
    @include link-with-arrow;
    display: inline-block;
    margin-bottom: 15px;
    padding-left: 0;
    color: #fff;
    padding: 5px 10px;

    &:before {
      display: none;
    }
  }
}
#RosterPlayerStats td.date,
#RosterPlayerStats td.name,
#RosterPlayerStats td.score,
#RosterPlayerStats td.split {
  white-space:nowrap;
}
#RosterPlayerStats td:first-child,
#RosterPlayerStats th:first-child { text-align:left; padding-left:10px; }
#RosterPlayerStats tr[data-toggles] td:first-child { padding-left:1.8em; }
#RosterPlayerStats tr.totals td { background-color:#fff; color:#222; }
#RosterPlayerStats tr.season td { background-color:#eee;}
#RosterPlayerStats tr.subseason td { background-color:#F7F7F7; }
#RosterPlayerStats tr.game_log + tr.subseason td { border-top:1px solid #CCC; }
#RosterPlayerStats tr.subseason_totals {
  background: #eee;
  td{
    background: #eee;
  }
}

#RosterPlayerStats tr.totals td,
#RosterPlayerStats tr.season td,
#RosterPlayerStats tr.subseason td {

}
#RosterPlayerStats tr.subseason td {
  color: #676767;
  font-weight: bold;
  font-size: 12px;
}
#GameLogDiv{
  .result {
    width: 7%;
    min-width: 50px;
  }
  .opponent {
    width: 20%;
  }
}

#CareerStatsDiv .statTable{
  td.description {
    text-align: left;
  }
  tr.season td.date {
    padding-left: 20px;
  }
}
#RosterPlayerStats .inactive_notice {
  color: white;
  background-color: #9f171d;
  padding: 10px 0 10px 10px;
  margin-bottom: 15px;
  width: 99%;
  strong{
    text-transform: capitalize;
  }
}
i.designation {
  background-image: url(/app_images/roster_player_designations.gif);
  display: inline-block;
  &.uncommitted { background-position: 0px 0px; width: 10px; height: 10px; }
  &.suspended { background-position: -15px 0px; width: 10px; height: 10px; }
  &.rookie { background-position: -30px 0px; width: 10px; height: 10px; }
  &.pmj { background-position: -45px 0px; width: 26px; height: 10px; }
  &.injured { background-position: -76px 0px; width: 10px; height: 10px; }
  &.import { background-position: -91px 0px; width: 18px; height: 10px; }
  &.committed { background-position: -114px 0px; width: 10px; height: 10px; }
  &.affiliate { background-position: -129px 0px; width: 10px; height: 10px; }
}
.rosterTableOverflow{
  overflow-x: auto;
  overflow-y:visible;
}

.rosterListingTable{
  .photo{
    width: 3%;

    img{
      border-radius: 100%;
      border: 3px solid #ddd;
      display: block;
      height: 50px;
      width: 50px;
      margin: 0 auto;
    }
  }
  .number {
    width: 3%
  }
  tbody td.name {
    text-align: left;
    width: 20%;
  }
  tbody td {
    width: 10%
  }
}
