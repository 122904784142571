.widget-multi-stat-filterable {
  [id^="multi_stat"] {
    overflow-x: auto;

    + div {
      @include line-pattern-border($pos:bottom);
      padding: 6px 12px;

      a {
        @include link-with-arrow;
        display: inline-block;
        text-align: right;
      }
    }
  }

  .sportTableLabel { @include sport-table-label; }

  .statTable {
    th {
      text-align: center;

      img { display: none; }

      &:first-child {
        text-align: center;
        width: 30px;
      }
    }

    td {
      text-align: center;
			white-space: nowrap;
			
      &:first-child { font-weight: 700; }
    }

    .statPlayer {
      text-align: left;

      a {
        display: inline-block;
        min-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 180px;
      }
    }
  }
}

@media (min-width: 768px) {
  .col-md-3,
  .col-md-4 {
    [id^="multi_stat"] {
      .sportTableLabel { @include sport-table-label-short; }
    }
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .layout-25-50-25 {
    .col-md-3,
    .col-md-6 {
      [id^="multi_stat"] {
        .sportTableLabel { @include sport-table-label-short; }
      }
    }
  }
}
