//specific overrides added here
//MN Boys Basketball Hub #ngin1255
#ngin1862 {
	.signup-link .linkElement h4 a:before {
		content: 'MN Football Hub';
	}
	.sn-link-small {
		h4 {
			font-size: 12px;
			@media only screen and (max-width: 360px) {
				font-size: 10px !important;
			}
		}
	}
	.divisionI {
		img {
			max-height: 50px;
		}
		.check:after {
			content: '\f00c';
			color: green;
			font-family: 'fontawesome';
			font-size: 32px;
			display: block;
			text-align:center;
		}
	}
}