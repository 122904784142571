/* News Article View */

// 1. News Article
// 2. Page layout padding/structure
// 3. Tags
// 1. News Article
.newsContentNode {
	.newsItemElement {
		padding-left: 15px;
	}
}

.newsItemColumn .newsItemElement {
	padding: 0px 15px;
	@media only screen and (max-width: 1024px) {
		padding: 0px;
	}
}

.newsItemElement {
	position: relative;
	margin-bottom: 0 !important;
	@media only screen and (max-width: 1024px) {
		&.pageElement {
			margin-top: 0;
		}
		padding-left: 0px;
	}
	.titleText {
		margin-bottom: rem(5);
		color: #444444;
		margin-right: 250px;
		font-family: $fontTwo;
		font-size: 32px;
		font-weight: 700;
		line-height: 1.25em;
		letter-spacing: -0.5px;
		@media only screen and (max-width: 768px) {
			font-size: 21px;
		}
	}
	.authorDetails {
		font-size: em(16);
		margin: 0;
		margin-right: 250px;
		color: #222;
		-webkit-font-smoothing: antialiased;
		.newsDate {
			font-size: 15px;
		}
		.newsDate,
		.newsAuthor {
			color: $accent-color;
		}
		.newsAuthor:before {
			content: 'By';
			margin-right: 4px;
		}
		.newsDate:before {
			content: '|';
			margin: 0 10px;
		}
		@media only screen and (max-width: 768px) {
			.newsDate,
			.newsAuthor {
				font-size: 13px;
			}
		}
	}
	.teaserText {
		font-weight: 400;
		line-height: normal;
		margin-bottom: 10px;
		color: $bodyCopyColorOne;
		margin-right: 250px;
		text-transform: none;
		font-size: 18px;
		@media only screen and (max-width: 768px) {
			font-size: 15.5px;
		}
	}
	.imageOriginal {
		display: block;
		width: 100%;
		border: $borderWidth solid $borderColor;
		margin-bottom: 1rem;
	}
	.newsArticleThumbnail {
		float: left;
		margin: .35em 1em 0 0;
		border: $borderWidth solid $borderColor;
		height: 4em; // Double the "titleText" font-size
		display: none;
	}
	hr {
		display: none;
	}
	@media only screen and (max-width: 768px) {
		.titleText,
		.teaserText,
		.authorDetails {
			margin-right: 0;
		}
	}
}

// 2. Page layout padding/structure
.newsPage .yieldPageContent {
	padding-top: $bootstrap-padding-md * 2px !important;
}

@media only screen and (max-width: 768px) {
	.newsPage .yieldPageContent {
		padding-top: $bootstrap-padding-sm * 2px !important;
	}
}

// 3. Tags
.newsTags {
	font-size: .75rem;
	padding-top: 15px;
}

// 4. Social
.sn-share-icons {
	position: absolute;
	top: 0;
	right: 0;
	@media only screen and (max-width: 768px) {
		position: relative;
	}
}

// 5. Related content
.user_mode {
	.related-content-container {
		padding: 15px 0;
		border-top: solid 1px #cccccc;
		border-bottom: solid 1px #cccccc;
		&:after {
			content: '';
			clear: both;
			display: block;
		}
		.leftTextImage,
		.lrightTextImage {
			margin: 0 10px 0 0;
			img {
				width: 100% !important;
			}
		}
		p {
			line-height: 1.2em;
			a {
				color: #444444;
				transition: all $transition-standard;
				font-weight: bold;
				font-size: 16px;
				&:hover {
					color: $accent-color;
				}
			}
		} // Layout types
		&.layout-type-100 {
			.leftTextImage,
			.lrightTextImage {
				width: 40% !important;
			}
			p {
				float: left;
				width: calc(60% - 10px);
			}
		}
		&.layout-type-50-50 {
			.pageEl {
				width: 50%;
				float: left;
				&:nth-of-type(1) {
					padding: 0 10px 0 0;
				}
				&:nth-of-type(2) {
					padding: 0 0 0 10px;
				}
			}
			.leftTextImage,
			.lrightTextImage {
				width: 50% !important;
			}
			p {
				float: left;
				width: calc(50% - 10px);
			}
		}
		&.layout-type-33-33-33 {
			.pageEl {
				width: 33.3333333%;
				float: left;
				padding: 0 15px;
				&:nth-of-type(1) {
					padding: 0 10px 0 0;
				}
				&:nth-of-type(2) {
					padding: 0 5px;
				}
				&:nth-of-type(3) {
					padding: 0 0 0 10px;
				}
				.leftTextImage,
				.lrightTextImage {
					margin: 0 0 7px 0;
					width: 100% !important;
				}
			}
		}
		@media only screen and (max-width: 1024px) {
			&.layout-type-100,
			&.layout-type-50-50,
			&.layout-type-33-33-33 {
				.pageEl {
					width: 100%;
					padding: 0 !important;
					.leftTextImage,
					.lrightTextImage {
						margin: 0 10px 0 0;
						width: 40% !important;
					}
					p {
						float: left;
						width: calc(60% - 10px);
					}
				}
			}
		}
	}
}

// Inline related content
.inline-related-content {
	.leftTextImage,
	.rightTextImage {
		padding: 30px 0 0;
		border-top: solid 1px #cccccc;
		border-bottom: solid 1px #cccccc;
		.cutline {
			padding-bottom: 30px;
		}
	}
	.leftTextImage {
		margin: 0 30px 30px 0;
	}
	.rightTextImage {
		margin: 0 0 30px 30px;
	}
	@media only screen and (max-width: 320px) {
		.leftTextImage,
		.rightTextImage {
			width: 100% !important;
			.cutline {
				width: 200px !important;
			}
		}
	}
	&:hover {
		.cutline {
			color: $accent-color;
			text-decoration: none;
			a {
				color: $accent-color;
				text-decoration: none;
			}
		}
	}
	.cutline {
		background: transparent;
		font-size: 16px;
		padding: 10px 0;
		color: #444444;
		font-weight: bold;
		font-style: normal;
		line-height: normal;
		transition: all $transition-standard;
		&:hover {
			color: $accent-color;
			text-decoration: none;
			cursor: pointer;
		}
		a {
			color: #444444;
			font-weight: bold;
			transition: all $transition-standard;
			&:hover {
				color: $accent-color;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.inline-related-content {
		.leftTextImage.smallTextImage,
		.leftTextImage.mediumTextImage,
		.leftTextImage.largeTextImage {
			width: 100% !important;
			padding: 15px 0 10px;
			&>a {
				display: flex;
				flex-flow: row no-wrap;
				img {
					min-width: 130px;
					padding-right: 15px;
				}
			}
		}
	}
}