// Changes cursor to a pointer on table headings to indicate it's a sortable column
body.teamPage #league table.statTable {
  th {
    cursor: pointer;
  }
  td {
    background: transparent !important;
  }
  tr {
    &:nth-child(even) {
      background-color: #eee;
    }
  }
}