//tabbed nav SCSS requires two steps
//1. include this mixin in the appropriate hub scss file nested within the siteId
//2. Make sure the appropriate siteNumber is set within the _tabbed_nav.js tabbedNavSites array
//3. Make sure the tabs in the top navigation are boys, girls, and more sections.
@mixin tabbedNav(){
  body.user_mode{
    #topNavPlaceholder{
      height: 170px;
    }
    #topNav{
      border-bottom: 2px solid $link-color;
      .site-tagline-text{
        padding-bottom: 30px;
      }
      .theme-main-nav{
        border-bottom: 0px;
        position: relative;
        ul.theme-nav-dropdown{
          position: relative !important;
        }
        .theme-nav{
          line-height: inherit;
          left: 80px;
          .theme-nav-item{
            position: absolute;
            display: inline-block;
            width: auto;
            top: -25px;
            z-index: 9999;
            border-bottom: 0px;
            height: 25px;
            &.sn-home-link{
              position: absolute;
              top:0px;
              background: #fff;
              border-right: 1px solid #222;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              width: 90px;
              left:-90px;
              a{
                color: #000 !important;
                font: normal 16px/1.2em $fontOne !important;
                text-align: center;
                //padding: 0px;
								height: auto;
								margin-right: -15px;
								&:hover{
									background:#000 !important;
									color:#fff !important;
									line-height:40px !important;
								}
              }
            }
            &.home{
              margin-left:auto;
            }
            a{
              background: transparent !important;
              color: $background-color !important;
              font-size: 14px !important;
              border-right: 0px !important;
              height: 25px;
              display: block;
            }
            &.sn-active-tab{
              background: $background-color !important;
              a, a:hover, &.selected{
                color: $link-color !important;
              }
              a{
                background: transparent !important;
                color: $link-color !important;
              }
						}
						&:first-child{
							left:24px;
						}
            &:nth-child(2){
							//left: 65px;
							left:90px;
            }
          }
        }
        //
        .theme-nav-style-dropdown ~ .theme-nav-dropdown{
          border: 0px;
          > .theme-nav-item{
            display: inline-block;
            width: auto;
            border-bottom: 0px;
            a{
              height: 40px;
							color: $link-color;
							&:hover{
								background:#000;
								color:#fff;
							}
            }
          }
          display: none !important;
          &.sn-active-list{
            animation: fade-in 2s;
            display: block !important;
            .theme-nav-item:first-child{
              a{
                padding-left: 22px;
              }
            }
          }
          &.dropdown-align-left{
            display:block;
            width: 100% !important;
            position: relative !important;
            max-width: $maxWidth;
            margin: auto;
            left: 0 !important;
            top: 0 !important;
            height: 40px;
            background: $background-color;
            white-space: nowrap;
            overflow-y: hidden;
            padding-left: 80px;
          }
          &.dropdown-open[data-nav-level="2"]{
            width: 100% !important;
            position: relative !important;
            max-width: $maxWidth;
            margin: auto;
            left: 0 !important;
            top: 0 !important;
						height: 40px;
						overflow:visible !important;
          }
          &[data-nav-level="3"]{
            display: none;
          }
        }
      }
    }
  }
}
