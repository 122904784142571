// Reformats game-header for mobile
@media only screen and (max-width: 400px) {
	#GameShowScoreboardSimple {
		.game-header {
			display: flex;
			flex-flow: row wrap;
			text-align: center;
		}
		.game-header .team-abbrev {
			display:none;
		}
		.game-header .team-name:first-of-type {
			order: 1;
			display:block;
			text-align: center;
		}
		.game-header .team-crest:first-of-type {
			order: 2;
			text-align: center;
		}
		.game-header .team-name:last-of-type {
			order: 4;
			display:block;
			text-align: center;
		}
		.game-header .team-crest:last-of-type {
			order: 5;
			text-align: center;
		}
	}
}