//specific overrides added here
//MN Girls Hockey Hub #ngin4204
#ngin4204{
  .signup-link .linkElement h4 a:before{
    content:'MN Girls’ Hockey Hub';
  }
  .sn-link-small{
    h4{
      font-size: 12px;
      @media only screen and (max-width: 360px){
        font-size: 10px !important;
      }
    }
  }
}
