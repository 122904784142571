// 5. Site Footer
.snFooterContainer {
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	background-color: $accent-color;
}

#siteFooter {
	@extend %clearfix;
	display: block;
	margin: auto;
	padding: 10px 20px;
	position: relative;
	vertical-align: bottom;
	width: 100%;
	font-size: .8em;
	clear: both;
	max-width: $maxWidth;
	p {
		font-size: 14px;
	}
	>ul {
		padding: 0;
		@include displayCenter($maxWidth);
		@media only screen and (max-width: 768px) {
			text-align: center;
		}
		li {
			display: inline-block;
			padding: 0 .2em;
			min-height: 20px;
			color: white;
			a {
				display: inline-block;
				color: white;
			}
			&:hover {
				display: inline-block;
			}
		}
	} // Custom footer
	#footer-content-container {
		* {
			color: white;
		}
		&:after {
			content: '';
			clear: both;
			display: block;
		}
		.pageElement.sn-social-media-list {
			margin-top: 0;
			display: flex;
			align-items: left;
			justify-content: flex-start;
			flex-flow: row-reverse;
			flex-wrap: wrap;
			&:before {
				content: 'Stay Connected';
				display: block;
				position: relative;
				font-family: $fontOne;
				font-size: 15px;
				font-weight: 600;
				width: 100%;
				padding-bottom: 10px;
			}
			&,
			&.light,
			&.dark {
				.sn-social-media-icon {
					background: white;
					color: $accent-color !important;
					border-radius: 0;
					margin: 0;
					transition: all $transition-standard;
					margin-right: 10px;
					&:hover {
						opacity: .75;
					}
					&:first-child {
						margin-right: auto;
					}
					@media only screen and (max-width: 768px) {
						&:last-child {
							margin-left: auto;
						}
					}
				}
			}
		}
		.pageElement.heroPhotoElement {
			margin-top: 5px;
			img {
				max-width: 250px;
				margin: 0;
				border: 0px;
			}
		}
	}
	#footer-left-container,
	#footer-right-container {
		float: left;
	}
	#footer-left-container {
		width: 35%;
		padding-right: 5%;
		box-sizing: border-box;
		.textBlockElement>h3 {
			font-weight: 100;
			font-size: 2vw;
			margin-bottom: 0;
			text-transform: none;
		}
	}
	#footer-right-container {
		width: 65%;
		.pageEl {
			float: left;
			box-sizing: border-box;
			padding-right: 20px;
			flex-grow: 1;
		}
		.column {
			display: flex;
		}
		.pageElement.textBlockElement {
			&.no-header {
				padding-top: 36px;
			}
			>h3 {
				font-family: $fontOne;
				font-size: 14px;
				text-transform: none;
				margin-bottom: 15px;
			}
			p {
				margin-bottom: 3px;
				a {
					font-weight: 100;
					-webkit-font-smoothing: antialiased;
					transition: all $transition-standard;
					white-space: nowrap;
					&:hover {
						text-decoration: none;
						-webkit-font-smoothing: auto;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width: 1200px) {
		#footer-left-container {
			width: 32%;
			padding-right: 3%;
		}
		#footer-right-container {
			width: 68%;
		}
	}
	@media only screen and (max-width: 960px) {
		#footer-left-container {
			width: 100%;
			padding-right: 0;
			.column {
				display: flex;
			}
			.pageEl {
				padding-right: 30px;
				flex-grow: 1;
			}
		}
		#footer-right-container {
			width: 100%;
		}
	}
	@media only screen and (max-width: 768px) {
		#footer-left-container {
			.textBlockElement>h3 {
				font-size: 24px;
			}
			.column {
				display: block;
				text-align: center;
			}
			.pageEl {
				padding-right: 0;
			}
		}
		#footer-right-container {
			display: none;
			.column {
				display: block;
				text-align: center;
			}
			.pageEl {
				float: none;
			}
			.pageElement.textBlockElement.no-header {
				padding-top: 0;
			}
			.pageElement {
				margin: 0;
			}
			.pageElement.textBlockElement>h3 {
				margin-top: 15px;
			}
		}
		#footer-content-container .pageElement.heroPhotoElement img {
			margin: auto;
			border: 0px;
		}
	}
}

#siteFooter li#poweredByNGIN {
	float: right;
	a {
		display: block;
		width: 120px;
		height: 20px;
		position: relative;
		@media only screen and (max-width: 768px) {
			margin: auto;
		} // &:before {
		//   content: 'powered by';
		//   display: inline-block;
		//   margin-right: 5px;
		//   font-weight: 100;
		//   font-size: 12px;
		// }
		&:after {
			content: url($asset-path-for+'/logo_images/logo.svg');
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			width: 100%;
		}
		img {
			display: none;
		}
	}
}

@media only screen and (max-width: 1024px) {
	#siteFooter {
		li {
			min-height: 0;
		}
	}
	#siteFooter li#poweredByNGIN {
		padding: 10px 0;
		width: 100%;
	}
}

@media only screen and (max-width: 640px) {
	#siteFooter {
		li {
			min-height: 0;
		}
	}
	#siteFooter li#poweredByNGIN {
		padding: 10px 0;
	}
}

.user_mode {
	#siteFooter li a.legalLink[href^="/help"] {
		display: none;
	}
}