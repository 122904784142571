#help_link, #membership-prompt_tip, #sovrn_beacon{
  display: none !important;
}
.selectedNodes .nodePicker-site-name img{
  display: none;
}
.NGIN_tip.user_nav_menu h3{
  color: $accent-color;
  text-transform: none;
  font-size: 18px;
}
body.gameShow, body.divisionPage, body.leaguePage, body.bracketPage{
  #sport-header .sport-colors{
    padding-left: 0px;
    @media only screen and (max-width: $maxWidth){
      padding-left: 10px;
    }
    @media only screen and (max-width: 768px){
      padding-left: 10px;
    }
    .team-logo-wrapper{
      display: none;
    }
  }
}
body.sportsPage,body.bracketPage {
  .yieldPageContent {
    select {
      font-size: 16px;
    }
  }
}
//need to find a better solution to target this link on mobile... there is no title tag
html{
  .theme-nav-style-slide{
    .theme-nav-item{
      a[href="http://www.startribune.com/sports/"],a[href="http://www.startribune.com/sports/"], #page_node_3711299{
        color:transparent;
        display: block;
        background-image:url('../images/site/logos/star-tribune-sports-logo_flat-w.svg');
        background-repeat: no-repeat;
        background-size: 200px 30px;
        background-position: 10px center;
        background-color: #222;
      }
    }
  }
}
#panelTwo{
  padding: 0px;
  @media only screen and (max-width: 1024px) {
    padding: 10px 5px 0px 5px;
    .column{
      width: 100% !important;
    }
  }
}
body.home:not(.newsPage){
  #adWrapperTopLeaderboard{
    padding: 0px;
    margin: 0px;
    height: 25px;
    display: none;
    @media only screen and (max-width: 1024px) {
      height: 0px;
    }
  }
}
.newsContentNode{
  .column-1{
    .heroPhotoElement{
      margin-bottom: 0px;
    }
  }
}
.newsContentNode .column-1 .text, .newsContentNode .column-1 .cutline{
  padding-right:15px;
  padding-left:15px;
  @media only screen and (max-width: 1024px){
    padding-right:0px;
    padding-left:0px;
  }
}
.newsContentNode .column-1 {
  .rightTextImage, .leftTextImage{
    .cutline{
      padding-right:0px;
      padding-left:0px;
    }
  }
}
#siteHeader{
  @include hideElement;
}
#topNav, #siteContainer, .snFooterContainer{
  //opacity: 1;
  transition: opacity 300ms ease-in-out;
}
html.sn-dropdown-open{
   //overflow: hidden;
  //  #topNav, #siteContainer, .snFooterContainer{
  //    opacity: 0.4;
  //  }
}
html.nav-fixed body.sn-scrolling-intro{
  #topNav, #accountNav{
    top:0px !important;
    opacity:1 !important;
  }
}
html.nav-fixed body.sn-scrolling-up{
  #topNav, #accountNav{
    top:0px;
    opacity:1;
  }
}
html:not(.page-manager-visible){
  //
}
html.nav-fixed{
  &.page-manager-visible #accountNav{
    margin-left: 60px;
  }
  #topNav, #accountNav{
    top:-100px;
    opacity: 0;
    transition: top 300ms ease-in-out, opacity 300ms ease-in-out;
  }
  #topNav .site-tagline-text{
    display: none;
  }
  #accountNav {
    position: fixed;
    border-bottom: $site-tagline-separator;
    li{
      &.site-tagline-text-header{
        display: flex;
        padding-left: 12px;
        padding-top: 6px;
        padding-bottom: 8px;
      }
    }
  }
}
//search
#accountNav {
  li{
    &#login{
      a{
        text-transform: uppercase;
        font-size:14px;
        font-weight: 300;
        color: #fff;
        padding: 0px 25px;
      }
    }
    //&.currentSite
    &#account_new, &#help_and_updates{
      display: none;
    }
    &.site-tagline-text-header{
      @include site-tagline-text-header;
      display: none;
    }
    &.sn-dropdown{
      position: relative;
      -webkit-animation: fade-in .6s ease-in;
      animation: fade-in .6s ease-in;
      &:before{
        content:'';
        width: 1px;
        background: #fff;
        height: 60%;
        position: absolute;
        right: 0;
        top: 20%;
        bottom: 0;
      }
      &:after{
        $border-triangle-size:10px;
        content:'';
        width: 0;
        height: 0;
        border-left: $border-triangle-size solid transparent;
        border-right: $border-triangle-size solid transparent;
        border-bottom: ($border-triangle-size/3 + $border-triangle-size) solid #fff;
        position: absolute;
        //top: -($border-triangle-size/3 + $border-triangle-size);
        left: 45%;
        top: 100%;
        z-index: 2;
        display: none;
      }
      &.sn-active{
        > a.sn-dropdown-btn{
          color:#ccc;
          &:after{
            color: #ccc;
            font-size: 13px;
          }
        }
        &:after{
          display: block;
        }
        .sn-dropdown-wrap{
          display: block;
          -webkit-animation: dropdown-season $transition-standard;
          animation: dropdown-season $transition-standard;
        }
      }
      &#sn-dropdown-hub{
        &.sn-active{
          > a.sn-dropdown-btn{
            &:after{
              content:'\f0d8';
              font-size: 13px;
            }
          }
        }
        a.sn-dropdown-btn{
          &:before{
            content:'';
            display: inline-block;
            width: 100px;
            height: 40px;
            background-image:url('../images/site/logos/star-tribune-logo_flat-w.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center 10px;
            margin-right: 15px;
          }
          &:after{
            content:'\f0d7';
            display: inline-block;
            font:normal 14px/1.5em "FontAwesome";
            padding-left: 10px;
            width: 20px;
            height: 20px;
          }
        }
        ul.sn-dropdown-container{
          padding-bottom: 10px;
          ul.sn-dropdown-item{
            &:last-child{
              border-bottom: 0px;
              padding-bottom: 0px;
            }
          }
        }
      }
      &#sn-dropdown-all{
        > a.sn-dropdown-btn{
          &:after{
            content:'\f0c9';
            display: inline-block;
            font:normal 14px/1.5em "FontAwesome";
            padding-left: 10px;
            width: 20px;
            height: 20px;
          }
        }
        .sn-dropdown-wrap{
          &:after{
            content:'';
            font:400 14px/1.5em $fontThree;
            text-transform: uppercase;
            color: #666;
            display: block;
            background-image:url('../images/site/logos/star-tribune-sports-logo_flat-c.svg');
            background-repeat: no-repeat;
            background-size: 80%;
            background-position: 10px center;
            background-color: #fff;
            height: 40px;
            width: 100%;
            position: absolute;
            bottom: 0;
            border-top: 1px solid #222;
            margin: auto;
            left: 0;
            right: 0;
          }
          a.sn-star-link{
            position: absolute;
            display: block;
            width: 200px;
            bottom: 0;
            height: 40px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 99;
          }
        }
        ul.sn-dropdown-container{
            overflow-y: scroll;
            position: relative;
            width: 200px;
            top: 0px;
            box-shadow: none;
            padding: 0px;
            left: 0;
            padding: 0px 10px 40px 10px;
        }
      }
      > a.sn-dropdown-btn{
        position: relative;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 25px 0px 10px;
        &:after{
          transition: color 300ms ease-in-out, font-size 300ms ease-in-out;
        }
      }
      .sn-dropdown-wrap{
        position: absolute;
        width:200px;
        top:52px;
        box-shadow:0px 1px 3px 0px #999;
        background-color: #fff;
        max-height: 80vh;
        overflow: hidden;
        display: none;
      }
      ul.sn-dropdown-container{
        display: block;
        position: relative;
        width:200px;
        top:0px;
        box-shadow:0px 1px 3px 0px #999;
        padding: 0px 10px 0px 10px;
        background-color: #fff;
        max-height: 80vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        ul.sn-dropdown-item{
          display: block;
          border-bottom: 1px solid $accent-color;
          padding-bottom: 10px;
          &:last-child{
            border-bottom: 0px;
          }
          @at-root .user_mode #{&} {
            li a[title="click to go to 'More Sections'"],
            li a[title*="More Sections"],
            li a[title="Star Tribune Sports"],
            li a[title="click to go to 'Minnesota Boys' Hockey Hub More Sections'"]{
              display: none !important;
            }
          }
          &.sn-edit{
            padding-top: 20px;
            padding-bottom: 10px !important;
            li{
              a{
                background: #fccd22;
                color: $accent-color !important;
                text-align: center;
                margin: auto;
                width: 100px;
                border: 1px solid #DDA301;
                font-weight: normal;
                transition: color 300ms ease-in-out, background 300ms ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                &:after {
                  color: $accent-color;
                  content: '\f061'; // fa-arrow-right
                  display: inline-block;
                  font-family: 'FontAwesome';
                  margin: 0 0 0 .4em;
                  font-size: .6em;
                  font-weight: 400;
                  padding-left: 3px;
                  font-weight: normal !important;
                }
                &:hover{
                  color:#000;
                  background: #FDE872;
                  font-weight: normal !important;
                }
              }
            }
            @at-root .user_mode #{&} {
              display: none;
            }
          }
        }
        h3{
          padding: 9px 0px 4px 0px;
          font:700 14px/1.5em $fontFour;
          text-transform: capitalize;
          color: #666;
          &:first-child{
            padding-top: 6px;
          }
        }
        li{
          display: block;
					width: 100%;
					// font: 700 14px/1.5em "Lato",sans-serif;
					// color: #666 !important;
					// &:first-child{
					// 	padding-top:6px;
					// 	-webkit-font-smoothing: antialiased;
					// }
          a{
            background-color: #fff;
            color: #777 !important;
            display: block;
            width: 100%;
            //transition: color 300ms ease-in-out, font-weight 300ms ease-in-out;
            white-space: normal;
            font: 400 .85em/2.3em "Open Sans" !important;
            padding: 0px;
            span, font{
              color: #666 !important;
            }
            &:hover{
              color: $accent-color !important;
              font-weight: 600 !important;
              span, font{
                color: $accent-color !important;
                font-weight: 600 !important;
              }
            }
          }
        }
      }
    }
  }
}

// footer login - logout
.snFooterContainer {
  li{
    list-style: none;
    &#login,
    &#logout{
      border-top: solid 1px #393939;
      text-align: center;
      a{
        text-transform: uppercase;
        font-size:14px;
        font-weight: 300;
        color: gray;
        padding: 10px;
        display: block;
      }
    }
  }
}

img[src*="loggly"]{
  display: none;
}

// .game_link_referrer{
//   position: relative;
//   &:after{
//     content:'>';
//     display: inline-block;
//     font:300 1em/1.5em "FontAwesome";
//     position: relative;
//   }
//   &:before{
//     content:'Final';
//     display: inline-block;
//     font:300 1em/1.5em $fontOne;
//     position: relative;
//   }
// }
.teamTagFilter{
	padding: 15px 0px;

	.teamPage &{
			display:none;

	}
}

.dataTable thead th.ajax_sort:after, .player_stats-list thead th.ajax_sort:after{
  font-size: 0px;
}
#team-sm-ice_hockey_skater, #team-sm-ice_hockey_goalie, #team-sm-ice_hockey_team_stats{
  .NginTableWrapper{
    border: 0px;
  }
}
#team-sm-ice_hockey_goalie-table{
  &.dataTable tr.even td, &.player_stats-list tr.even td, &.statTable tr.even td, &.stat_table tr.even td{
    background: #fff;
  }
  &.dataTable tr.odd td, &.player_stats-list tr.odd td, &.statTable tr.odd td, &.stat_table tr.odd td{
    background: #eee;
  }
}
.player_stats-list-header{
  display: flex;
  .team_logo{
    a{
      background-position: center;
    }
  }
  li{
    display: flex !important;
    align-items: center;
  }
  h2{
    padding-left: 15px;
  }
}

#GameLogDiv .statGraphAndTableContainer{
	display:none;
  h3{
    font-size: 16px;
  }
}
#PreviewStatsDiv{
  .game_link_referrer.button-small{
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
  }
}
#TeamStatsDiv{
  .NginTableWrapper{
    border: 0px;
  }
  .NginTableScroll{
    border: 1px solid #ccc;
  }
}
#tab_completegamelist_content{
  border: 1px solid #ccc;
  .pageElement{
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
#tab_gamelist_content{
  overflow-x: auto;
  border: 1px solid #ccc;
  .pageElement{
    margin-top: 0px;
    margin-bottom: 0px;
    table{
      border-bottom: 1px solid #ccc;
      tbody{
        tr{
          &:nth-child(odd) td{
            background: #eee;
          }
          &:nth-child(even) td{
            background: #fff;
          }
        }
      }
      tr{
        th{
          &.sorttable_sorted{
            color: #fff;
          }
        }
        td, th{
          text-align: left !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  html.has-mobile-nav{
    &.collapsed-mobile-nav .nav-fixed .theme-mobile-nav{
      display: block !important;
    }
    &.collapsed-mobile-nav.nav-fixed:not(.slide-nav-open) .theme-slide-nav-toggle{
      position:fixed;
      z-index: 2;
      right: auto;
      left: 0;
      top:0px;
      height: 40px;
      opacity: 1;
    }
    &.nav-fixed{
      body{
        #topNav, #accountNav{
          top:$account-nav-height-mobile !important;
        }
      }
      #accountNav{
        li{
          &.site-tagline-text-header{
            //padding-top: 16px;
          }
        }
      }
    }
    #accountNav{
      display: block;
      z-index: 399;
      li{
        &.site-tagline-text-header{
          display: flex;
          max-width: 75%;
          margin: auto;
          //font-size: 20px;
          padding-top: 16px;
          @media only screen and (max-width: 380px){ max-width: 100%; }
        }
      }
    }
    .theme-slide-nav-content{
      .site-tagline-text-header{
        @include site-tagline-text-header;
        width: 100%;
        padding: 10px 10px 10px;
        display: flex;
        max-width: 100%;
        margin: auto;
        //font-size: 20px;

        &:before {
          //height: 25px;
          //width: 25px;
        }

      }
    }
  }
}
.theme-nav-item-asset{
  .theme-nav-item{
    display: none;
  }
}
.user_mode #panelTwo .sn-widget,
.edit_mode #panelTwo .sn-widget{
  display: none;
}
#panelTwo .sn-widget-load{
  .pageEl{
    position: relative;
    &.sn-results, &.sn-schedule{
      padding: 15px;
    }
  }
  .pageElement{
    margin: auto;
  }
  .linkElement{
    padding: 0px;
    margin: 0px;
  }
  .sn-schedule{
    table tbody{
      display: flex;
      align-items: left;
      flex-flow: column-reverse;
      flex-wrap: wrap;
      a.link-wrap{
        width: 100%;
        &:last-child{
          tr{
            border-bottom: 1px solid #ccc !important;
          }
        }
        &:first-child{
          tr{
            border-bottom: 0px !important;
          }
        }
      }
      td{
        &.status{
          a{
            color: transparent !important;
            text-indent: -9999px;
            &:before{
              display: none !important;
            }
          }
        }
      }
    }
  }
  .widgetElement{
    #scoreboard_container{
      display: none;
      .edit_mode &{
        display: block;
      }
      .scoreboardRibbon{
        height: auto;
      }
    }
    //@include setScroll;
    .theme-game-list-widget{
      border: 0px;
      .extendedOptions{
        display: none;
      }
      table{
        a.link-wrap{
          font-weight: inherit;
          display: block;
          &:hover{
            td a{
              color: #444 !important;
            }
          }
          &:last-child{
            tr{
              border-bottom: 0px;
            }
          }
        }
        tr{
          padding:10px 0px 15px 0px;
          display: flex;
          align-items: left;
          justify-content: left;
          flex-wrap: wrap;
          border-bottom: 1px solid #ccc;
          margin: 0px;
          td{
            top: auto;
            left: auto;
            position: relative;
            width: 50% !important;
            margin: auto;
            padding: 0px;
            background: #fff;
            height: auto;
            &.venue-subvenue { display: none; }
            &.theme-team-slot{
              position: relative;
              width: auto;
              font:bold 14px/1.5em $fontOne;
              a{
                color: $accent-color;
                &:hover{
                  color: #444;
                }
              }
            }
            &.date-time{
              text-transform: none;
              color:#222;
            }
            &.away-team-score, &.home-team-score{
              text-align: right;
              font:bold 14px/1.5em $fontOne;
              padding-right: 10px;
            }
            &.status{
              a{
                background: #fff;
                color: #666;
                text-align: left;
                padding: 5px 10px 5px 0px;
                &:before{
                  content: '—';
                  display: inline-block;
                  padding-right: 5px;
                  font:400 1em/1.5em $fontOne;
                }
                &:after{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    padding: 0px;
    margin:0px;
    height: auto;
    background:#fff;
    .previous_button, .next_button{
      display: none;
    }
    .container{
      width: 100% !important;
      position: relative;
      left: auto;
      right: auto;
      height: auto;
      border: 0px;
      background: transparent;
      padding: 0px;

      ul{
        width: 100% !important;
        left: auto !important;
        display: flex;
        justify-content: left;
        flex-flow: column;
        //reversing order of games to have most recent dat first...
        li{
          display: block;
          border-bottom: 1px solid #ccc;
          &:first-child{
            //border-bottom: 0px;
          }
          &:last-child{
            //border-bottom: 0px;
          }
          &.game, .gameLink{
            width: 100%;
            display: block;
            height: auto;
          }
          .gameLink { border: none; }
          a{
            padding: 10px 0px 15px 0px;
            &.in_progress .status{
              font-style: normal;
              color:#666;
            }
            &.completed .status{
              //
            }
            &.postponed .status{

            }
            &:hover{
              .team .name, .team .score, .date, .status{
                color: #444;
              }
            }
            > span{
              padding: 0px;
            }
            .date{
              background: transparent;
              color: $accent-color;
              letter-spacing: normal;
              text-transform: none;
              display: inline-block;
              margin-bottom: 0px;
              padding-top: 0px;
              width: 50%;
            }
            .team{
              .name{
                font:600 14px/1.5em $fontOne;
                color: $accent-color;
              }
              .score{
                font:600 14px/1.5em $fontOne;
                color: $accent-color;
              }
            }
            .status{
              display: inline-block;
              width: 50%;
              //display: none;
              padding-top:0px;
              &:before{
                content: '—';
                display: inline-block;
                padding-right: 5px;
                padding-left: 5px;
                font:400 1em/1.5em $fontOne;
              }
            }
            .boom{
              .name{

              }
              .score{

              }
            }
          }
        }
      }
    }

  }
  .layoutContainer, .tabContainer .column{
      padding: 0px;
    }
  @media only screen and (max-width: 1024px) {
    .tabbedElement{
      ul.contentTabs{
        display: block;
        li{
          width: 50%;
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    //padding: 0px 10px;
  }
}
//
.pageEl{
  &.sn-tab-inline{
    .tabbedElement ul.contentTabs li{
      width:auto !important;
      @media only screen and (max-width: 400px){
        span a{
          font-size:12px;
        }
        &:after{
          height:4px;
          bottom:-3px;
        }
      }
      @media only screen and (max-width: 320px){
        span a{
          font-size:9px;
        }
      }
    }
  }
  &.sn-view-all-link{
    padding: 0px;
    margin: 0px;
    .linkElement{
      margin: 0px;
    }
    h4{
      a{
        background-color:transparent;
        text-align:right;
        color:#222;
        font:600 16px/1.5em $fontOne;
        &:before{
          content: '\f061';
          position: relative;
          top: 0;
          left: 0;
          padding-right: 5px;
          font-family: FontAwesome;
          font-size: 14px;
          display: inline-block;
          text-align: right;
        }
        &:hover{
          background: transparent;
          color: #000;
        }
      }
    }
    .text{
      display:none;
    }
  }
  .rankingsElement{
    .NginTableWrapper{
      position: relative;
    }
    .NginTableScroll{
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    table {
      td:last-child{
        display: none;
      }
      tr td{
        font-weight: bold;

        a{
          font-weight: bold;
        }
      }
      thead tr{
        th:last-child{
          display: none;
        }
        th{
          font: 600 15px/1.2 $fontOne;
        }
      }
    }
  }
  &.sn-view-all{
    .newsAggregatorElement .pageOptions, .newsAggregatorElement .extendedOptions{
      display: block;
    }
  }
  &.sn-scroll-right{
    @media only screen and (max-width: 450px) {
      .widgetElement{
        position: relative;
        @include rightScrollArrow;
      }
    }
  }
  &.no-margin{
    .pageElement, .hrElement{
      margin-top:0px !important;
      margin-bottom: 0px !important;
      padding-top: 0px !important;
    }
  }
  &.no-margin-top{
    .pageElement{
      margin-top:0px !important;
    }
  }
  &.noPadding{
    padding: 0px !important;
    margin: auto;
    margin-top: 10px;
    h3{
      margin-bottom: 0px;
    }
  }
  &.sn-table-head-off{
    .widgetElement{
      table thead{
        tr{
          display: none;
          &:last-child{
            display: table-row;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    &.sn-hide-mobile{
      display: none;
    }
  }
  @media only screen and (min-width: 1024px) {
    &.sn-hide-desktop{
      display: none;
    }
  }
  @at-root .edit_mode #{&} {
    &.sn-hide-desktop, &.sn-hide-mobile{
      display: block;
    }
  }
  &.sn-title-sports{
    h3{
      text-indent: -99999px;
      font-size: 0;
      color: $accent-color;
      &:before{
        content:'Star Tribune Sports';
        text-indent: 0px !important;
        display: block;
        font-size: 24px !important;
        margin-bottom: .5em;
        color: $accent-color;
      }
    }
  }
  &.sn-title-preps{
    h3{
      text-indent: -99999px;
      font-size: 0;
      color: $accent-color;
      &:before{
        content: 'Preps Insider blog';
        text-indent: 0px !important;
        display: block;
        font-size: 24px !important;
        margin-bottom: .5em;
        color: $accent-color;
      }
    }
  }
	&.sn-rss-header{
		.pageElement.textBlockElement {
			margin-bottom: 0;
			h3 {
				margin-bottom: 0;
				line-height: normal;
			}
		}
		+ .pageEl .pageElement{
			margin-top: 0;
			.feeder>h3{
				display: none;
			}
		}
	}
  &.sn-tab-layout{
    .contentTabs{
      display: none;
    }
    .tabbedElement{
      .tabContainer{
        border: 0px;
      }
    }
    @at-root .edit_mode #{&} {
      .contentTabs{
        display: block;
      }
    }
  }
  .rankingsElement{
    h3{
      font:700 18px/1.5em $fontTwo !important;
      margin-bottom: .5em;
      color: #444;
      @media only screen and (max-width: 768px) {
        font-size: 16px !important;
      }
    }
  }
  .widgetElement{
    .widget-leader{
      border: 0px;
      .lastLi{
        table thead{
          display: none;
        }
        .leader-highlight{
          border: 1px solid #ccc;
          padding-top: 10px;
          border-bottom: none;
          .stats-major{
            line-height: 1em;
          }

          + .NginTableWrapper {

            .NginTableScroll { border-top: none; }

            .statTable {

              .highlight {

                &:first-of-type { display: none; }

              }

              tr.highlight {

              }

            }

          }

        }

        .highlight:nth-of-type(even) {
          td {
            background: #fff !important;
            color: #555;

						a {
							//color: $accent-color;
							color: #444;
						}

          }
        }

        .odd:nth-child(3){
          td{
            background: #eee !important;
            color: #555;
            a {
							//color: $accent-color;
							color: #444;
						}
          }
        }
        .highlight:nth-of-type(odd){
          td {
            background: #eee !important;
            color: #555;

            a { color: $accent-color; }

          }
        }

      }
    }
    .extendedOptions{
      a.goToLink.icon{
        padding: 7px 10px;
        font:400 12px/1em $fontOne;
        color: #fff;
        background: #222;
        transition: background 300ms ease-in-out, color 300ms ease-in-out;
        display: inline-block;

        &:hover{
          background: #000;
          color: #fff;
        }
        &:after{
          display: inline-block;
          font:400 11px/1.5em "FontAwesome";
          content: '\f061';
          padding-left: 5px;
        }
        &:before{
          display: none;
        }
      }
    }
  }
  .sn-scroll{
    .widgetElement{
      .widget-leader{
        $scrollHeight: 500px;
        .NginTableWrapper{
          height: $scrollHeight;
          padding: 0px;
          position: relative;
          @at-root .edit_mode #{&} {
            height: auto;
            .NginTableScroll{
              height: auto;
              overflow-y: auto;
            }
            &:before{
              display: none;
            }
          }
          @include scrollBottomOverlay;
          .NginTableScroll{
            overflow-y: scroll;
            height: $scrollHeight;
            padding-bottom: 50px;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
        @media only screen and (max-width: 1024px) {
          .NginTableWrapper{
            overflow: visible;
          }
          .NginTableScroll{
            padding-bottom: 200px;
          }
          .NginTableWrapper, .NginTableScroll{
            height: (($scrollHeight/3)*2)-15;
          }
        }
      }
    }
  }
  &.sn-hide-tabs-border{
    .contentTabs{
      display: none;
    }
    .widgetElement{
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .column{
      padding-left: 0px;
      @media only screen and (max-width: 1024px) {
        padding-left: 10px;
      }
    }
  }
  &.sn-hide-tabs{
    .tabbedElement .tabContainer{
      border: none;
    }
    .contentTabs{
      display: none;
    }
    .widgetElement{
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .column{
      padding-left: 0px;
      @media only screen and (max-width: 1024px) {
        padding-left: 10px;
        .widgetElement{
          margin-top: 15px;
          //margin-bottom: 0px;
        }
      }
    }
  }
  &.sn-game-highlight{
    .game-header{
      display: none;
    }
    .boxscore-container{
      border: none;
      padding: 0px;
      .team-home{
        td{
          background: #eee;
        }
      }
    }
  }
  &.sn-group{
    .tabContainer{
      padding: 10px;
      .layoutContainer{
        .column{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          .pageEl{
            width: 100%;
            margin: 0px;
            .linkElement{
              padding: 5px;
              h4 a{
                padding: 10px 15px;
              }
            }
            .heroPhotoElement{
              img{
                padding: 0 15px;
                border: 0px !important;
              }
            }

            .pageElement { margin: 7px 0; }

          }
        }
        @media only screen and (max-width: 1024px) {
          .column{
            padding: 0px !important;
          }
        }
      }
    }
  }
  $third: 33.3%;
  $fourth:25%;
  $fifth: 20%;
  &.sn-one-fifth{
    width: $fifth !important;
  }
  &.sn-two-fifth{
    width: $fifth*2 !important;
  }
  &.sn-three-fifth{
    width: $fifth*3 !important;
  }
  &.sn-one-half{
    width: $fourth*2 !important;
  }
  &.sn-one-fourth{
    width: $fourth !important;
  }
  &.sn-one-third{
    width: $third !important;
  }
  &.sn-two-third{
    width: $third*2 !important;
  }
  &.sn-three-fourth{
    width: $fourth*3 !important;
  }
  &.sn-center { margin: auto; }
}
// Page Divider
.hrElement {
  padding: $base-padding 0;
  border: none;
  border-image: none;
  border-bottom: solid 1px #cccccc;
  background: transparent;
}
.tableElement, .sportsPage{
  .NginTableScroll{
    overflow-x: auto;
  }
}
.layoutContainer .layoutContainer{
  padding-bottom: 10px;
}
.tabContainer{
  .column{

    &:last-child{

    }
  }
  .pageElement.widgetElement{
    .widgetHierarchy h4{
      padding-top: 0px;
      padding-bottom: 5px;
    }
  }
}
.sn-scroll-arrow{
  overflow-x: auto;
  //-webkit-overflow-scrolling: touch;
  @include rightScrollArrow;
  &::-webkit-scrollbar {
    display: none;
  }
}
// Text Block Headers with no or reduced bottom margin
.no-margin-header {

  .pageElement.textBlockElement {
    margin-bottom: 0;

    h3 {
      margin-bottom: 0;
      line-height: normal;
    }

  }

  .aggHeader {
    margin-bottom: 0;
    line-height: normal;
  }

}

.tableElement.pageElement > h3 {
  margin-bottom: 10px;
}

// Filters button
.sport-tools .filter-stats {
  border-left: none;
  padding: 0;

  li {
    border: none;

    #filters {
      background: transparent;
      padding: 10px 5px;
      color: $accent-color;
      border: none;

      &:after {
        content: '+';
        display: inline-block;
        margin-left: 5px;
      }

    }

  }

  li.selected #filters:after {
    content: '-';
  }

}

.NginTableWrapper {
  //overflow: scroll;
  border: 1px solid #ccc;
}
.team-sm_team_scoring .NginTableScroll{
  border: 1px solid #ccc;
  .NginTableWrapper{
    border: none;
  }
}
.GameShowDataColumn{
  box-shadow: none;
  //
}
@media only screen and (max-width: 768px) {
  #panelTwo .text p{
    font-size: 16px;
  }
}
#TeamInstanceStatsDiv .panes th.ajax_sort{
  padding-right: 15px;
}
.gsc-results .gsc-cursor-box .gsc-cursor-page{
  background: transparent !important;
}
//custom tab
.sn-widget-tab{
  position: relative;
  height: auto;
  @include scrollBottomOverlay;
  .sn-widget-tab-btn-container{
    display: flex;
    .sn-widget-tab-btn{
      display: inline-block;
      text-transform: none;
      color: #222;
      padding: 7px 14px 3px 14px;
      border: 0;
      font:bold 15px/1.5 $fontOne;
      align-items: left;
      text-align: center;
      justify-content: left;
      flex-wrap: nowrap;
      position: relative;
      &.selected{
        border: 1px solid #ccc;
        border-bottom: 0px;
        &:after{
          content: '';
          height: 6px;
          width: 100%;
          background:#fff;
          position: absolute;
          bottom: -3px;
          left: 0;
          z-index: 4;
        }
      }
      @media only screen and (max-width: 768px){
        width: 50%;
      }
    }
  }
  .sn-widget-tab-container{
    padding: 0px 15px 0px 15px;
    border: 1px solid #ccc;
    background-image:url('../images/site/icons/spinner.svg');
    background-position: center;
    background-repeat: no-repeat;
    @include setScrollAlt;
    -webkit-overflow-scrolling: touch;
    &.sn-loaded{
      background-image: none;
    }
    .sn-widget-tab-item{
      display: none;
      &.selected{
        display: block;
      }
      &.sn-widget-schedule{
        .status{
          display: none !important;
        }
      }
    }
    .container{
      position: relative;
      .game{
        float: left;
        padding-right: 2px;
      }
      .team{
        font-size: 12px;
        display: block;
        clear: both;
      }
      .score{
        float: right;
      }
      .date{
        white-space: nowrap;
        font:normal 11px/1.5em $fontOne;
      }
      .completed{
        .status{
          font-weight: bold;
        }
      }
      .status{
      clear: both;
      font-family: "Open Sans", sans-serif;
      font-size: 11px;
      font-weight: 600;
      padding-top: 10px;
      text-transform: uppercase;
      }
      a > span {
        color: #666;
        line-height: 1.5em;
      }
      .sn-scheduled{
        //manage direction with flex
      }
      .sn-progress{
        //manage direction with flex
        li{

        }
      }
      .sn-final{
        display: flex;
        flex-flow: column-reverse;
        width: 100%;
        position: relative;
        flex-wrap: nowrap;
        li{

          &:last-child{

          }
        }
      }
      .sn-noresults{
        padding: 10px;
        text-align: center;
        color: #ccc;
        font:normal 16px/1.5em $fontTwo;
        width: 100%;
        display: block;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  #player_positions{
    .stats-positions{
      width: 100%;
      display: block;
    }
  }
}

// Hide specific items on mobile
@media only screen and (max-width:768px) {
	.user_mode .mobile-hide {
		display: none;
	}
}
