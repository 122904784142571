/* Widget - Stat Leader
---------------------------------------------------------------------------------------------------- */
.widget-leader {
  .sportTableLabel { @include sport-table-label; }

  .statTable {
    width: 100%;

    th, td {
      &:nth-child(1) { text-align: center; } // rank column
      &:nth-child(2) { text-align: left; } // name column
      &:nth-child(3) { text-align: center; } // stat column
    }

    th:nth-child(1) { width: 30px; }

    td {
      white-space: nowrap;
      font-weight: bold !important;
      a{
        font-weight: bold !important;
      }
      &:nth-child(1) { font-weight: 400; }
    }
  }

  .leaderName {
    display: inline-block;
    width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    font-weight: 400;
  }

  .leader-highlight {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //-webkit-box-align: center;
    //-webkit-align-items: center;
    //-ms-flex-align: center;
    //align-items: center;
    padding: 0px 15px 15px 15px;
    background-color: #fff;
  }

  .stats {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 0 0 15px;
    text-align: left;
  }

  .stats-major,
  .stats-minor {
    @include font-smoothing;
    margin: 0;
    padding: 0;
    color: #444;
    a{
      color: #444;
      font-weight: 900;
      word-spacing: 99em;
    }
    &:after{
      display: none;
    }
  }
  .stats-major{
    font:900 45px/1.5em $fontTwo;
  }
  .stats-minor{
    font:900 14px/1.5em $fontTwo;
  }

  .player_photo {
    overflow: hidden;
    height: 70px;
    //border: 3px solid $link-color;
    background-color: #C7C7C7; // Same grey as default player photo

    img {
      display: block;
      width: 70px;
      border: 1px solid #ccc;

      &[src^="/app_images/no_photo_neutral.svg"] {
        border: 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        object-fit: cover;
        height: 100%;
      }

    }
  }
}
