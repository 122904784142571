$search-modal-width: 600px;
$search-modal-background: #222;
$search-modal-spacing: 25px;
$search-modal-border: 1px solid #d4d9dc;
$search-modal-border-radius: 4px;
$search-modal-color: $accent-color;
$search-modal-color-secondary: #222;

$search-modal-title-size: 22px;
$search-modal-close-size: 44px;
$search-modal-close-color: #fff;

$search-modal-box-font-size: 16px;
$search-modal-box-font-spacing: 12px;
$search-modal-box-color: #666;
$search-modal-box-background: $accent-color;
$search-modal-box-size: 44px;
$search-modal-box-border-width: 0;
$search-modal-box-border-color: transparent;

$search-modal-result-spacing: $slide-nav-content-spacing;
$search-modal-result-size: 44px;
$search-modal-result-border: 1px solid #d4d9dc;
$search-modal-result-background-hover: #f0f1f2;

$search-input-height: $search-modal-box-font-size + ($search-modal-box-size - $search-modal-box-font-size) / 2;

.theme-search-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,.5);
}

.theme-search-modal {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 50%;
  margin-left: $search-modal-width / -2;
  width: $search-modal-width;
  background: $search-modal-background;
  //border: $search-modal-border;
  border-radius: $search-modal-border-radius;
  @mixin theme-search-modal{
    width: 100%;
    margin-left: 0;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
    border: none;
  }
  @media only screen and (max-width: 1024px) {
    @include theme-search-modal;
  }
  @media only screen and (max-width: $search-modal-width + 50) {
    @include theme-search-modal;
  }

  // Generate modal title, since the actual title is in the kill link
  .theme-search-title {
    //padding: $search-modal-spacing;
    //padding-right: $search-modal-spacing + $search-modal-close-size;
    @include site-tagline-text-header;
    display: none;
    //padding-top: 11px;
    @media only screen and (max-width: 1024px) {
      display: flex;
    }
  }

  .theme-search-close {
    position: absolute;
    text-decoration: none;
    text-align: center;
    top: 0;
    right: 0;
    width: $search-modal-close-size;
    height: $search-modal-close-size;
    line-height: $search-modal-close-size;
    z-index: 2;

    &:before {
      content: "\f00d";
      color: $search-modal-close-color;
      font-family: FontAwesome;
      font-size: 18px;
    }
  }

  .theme-search-box {
    position: relative;
    //line-height: $search-modal-box-size;
    padding: $search-modal-result-spacing/2 $search-modal-result-spacing;
    margin: auto $search-modal-spacing;
    background: #fff;
    border-top: $search-modal-box-border-width solid $search-modal-box-border-color;
    border-bottom: $search-modal-box-border-width solid $search-modal-box-border-color;
    //height: $search-modal-box-size + $search-modal-box-border-width * 2;
    font-size: 12px;
  }

  .theme-search-input {
    color: $search-modal-box-color;
    vertical-align: middle;
    font-size: $search-modal-box-font-size;
    line-height: $search-input-height;
    padding: 0px;
    //padding: 0 $search-input-height 0 $search-modal-box-font-size/2;
    border: $search-modal-box-border-width solid $search-modal-box-border-color;
    background: #fff;
    //margin-top: -1px;
    width: 100%;
    box-shadow:none;

    &::-ms-clear { display: none; }
    &:focus { outline: none; }
  }

  .theme-search-clear {
    position: absolute;
    top: ($search-modal-box-size - $search-input-height) / 2;
    right: $search-modal-spacing;
    cursor: pointer;
    height: $search-input-height;
    line-height: $search-input-height;
    width: $search-input-height;
    text-align: center;
    color: $link-color;
    display: none !important;

    &:before {
      content: "✕";
      color: #9ca8af;
      font-family: FontAwesome;
      font-size: 14px;
    }
  }

  .theme-search-status {
    color: $search-modal-color-secondary;
    display: block;
    font-size: 12px;
    text-align: center;
    margin-top: -$search-modal-spacing/2;
    margin-bottom: $search-modal-spacing/2;
  }

  .theme-search-results {
    position: absolute;
    //top: $search-modal-spacing * 2 + $search-modal-title-size + $search-modal-box-size + $search-modal-box-border-width * 2;
    top:135px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $search-modal-spacing;
    overflow: auto;
    list-style: none;
    border-radius: 0 0 $search-modal-border-radius $search-modal-border-radius;
    background: $highlight-color;
    border: $search-modal-border;
    border-top:0px;
    @media only screen and (max-width: 1024px) {
      top:179px;
    }
    &::-webkit-scrollbar{
      display: none;
    }
  }

  .theme-search-result {
    cursor: pointer;
    display: table;
    line-height: 1.2;
    width: 100%;
    border-top: $search-modal-result-border;

    &:last-child { border-bottom: $search-modal-result-border; }
    span{
      transition: 300ms padding ease-in-out;
    }
    &.sn-selected{
      background-color: #fff;
      span{
        padding: 0px 10px;
      }
      &:hover{
        background-color: #fff !important;
        .theme-search-result-info{
          background-color: #fff !important;
        }
      }
    }

  }

  .theme-search-result-info {
    text-decoration: none !important;
    display: table-cell;
    vertical-align: middle;
    padding: $search-modal-result-spacing 0px;
    height: $search-modal-result-size;

    &:hover {
      background: $search-modal-result-background-hover;
    }
  }

  .theme-search-result-primary{
    font: normal 24px/1.5em "Open Sans";
    color: $search-modal-color;
    display: block;
  }

  .theme-search-result-secondary {
    display: block;
    color: $search-modal-color-secondary;
    font: normal 12px/1.5em "Open Sans";
  }
  .theme-search-no-result{
    font: normal 24px/1.5em "Open Sans";
    color: #aaa;
    text-align: center;
    width: 100%;
    display: block;
  }
}
//Search
#PageSearch{
  .theme-search-modal{
    //
  }
}
//Search Specific overrides
#GoogleSearchBoxTopNav{
  display: none;
  .theme-search-submit{
    display: none;
  }
}
#PageSearchBoxTopNav{
  .theme-search-submit{
    background: #eee;
    color: #222;
    padding: 6px;
    &:after{
      content: "\f002";
      font:normal 1em/1.5em "FontAwesome";
      display: inline-block;
      color: #222;
      padding-left: 6px;
    }
  }
}
#GoogleContentSearch{
  .gsc-search-button, .gsc-modal-background-image-visible{
    display: none;
  }
  .gsc-results-wrapper-overlay{
    position: relative !important;
    height: auto;
    width: auto;
    left: auto;
    top: auto;
    box-shadow: none;
  }
  .gsc-search-box{
    tbody, tr, .gsc-input{
      display: block;
      width: 100%;
    }
    .gsc-input{
      height: auto !important;
      line-height: 30px;
      box-shadow: none;
    }
    .gsc-input-box, .gsc-input-box-hover{
      border: 0px;
      height: 30px;
      box-shadow: none;
    }
  }
  .gsib_a{
    padding: 0px;
    display: block;
    width: 95%;
  }
  .gsib_b{
    display: none;
  }
  .gsc-results-close-btn-visible{
    display: none;
  }
  .gsst_a{
    padding-top: 10px;
  }
  .cse .gsc-control-cse, .gsc-control-cse{
    border: 0px !important;
    background: transparent !important;
  }
}
//Custom DOM Elements for search
%sn-search-tabs{
  padding: $search-modal-spacing $search-modal-spacing 0px $search-modal-spacing;
  background: $accent-color;
  a{
    color: #fff;
    background: rgba($accent-color, 0.7);
    padding: 10px 15px;
    text-decoration: none;
    display: inline-block;
    &:hover{
      text-decoration: none;
    }
    &.sn-active{
      background: #fff;
      color: $accent-color;
      text-decoration: none;
    }
  }
}
.sn-search-tabs{
  @extend %sn-search-tabs;
  @media only screen and (max-width: 1024px) {
    a{
      width: 50%;
      text-align: center;
      padding: 5px 10px;
    }
  }
  @media only screen and (max-width: 320px) {
    font-size: 14.5px;
  }
}
#sn-search-tabs-mobile{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.has-mobile-nav .theme-search-overlay{
  //display: none !important;
  &.theme-search-overlay-active{
    display: block !important;
  }
}
.sn-search-container{
  display: none;
}
#GoogleSearchBoxMobileNav{
  display: none;
}
