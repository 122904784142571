//specific overrides added here
//MN Boys Basketball Hub #ngin1255
#ngin1668 {
	.signup-link .linkElement h4 a:before {
		content: 'MN Baseball Hub';
	}
	.sn-link-small {
		h4 {
			font-size: 12px;
			@media only screen and (max-width: 360px) {
				font-size: 10px !important;
			}
		}
	}
	.sportsPage #TeamStatsDiv div#team-sm-baseball_team_scoring {
		display: none;
	}
	.statTable .winner {
		padding: 3px;
		margin-left: -3px;
		background-position: left 50%;
	}
	.game-header {
		padding-bottom: 10px;
	}
	// .layoutContainer.layout-33-33-33 .widget-leader .statTable {
	// 	width: 100%;
	// 	max-width: 228px;
	// 	overflow: hidden;
	// }
	.widget-leader .leaderName {
		//width: 90px;
	}
}
