//specific overrides added here
//MN Boys Basketball Hub #ngin1255
#ngin556 {
  .signup-link .linkElement h4 a:before {
    content:'MN Lacrosse Hub';
  }
  .sn-link-small {
    h4{
      font-size: 12px;
      @media only screen and (max-width: 360px){
        font-size: 10px !important;
      }
    }
  }
	//@include keywordSearchOnlyOverride;
	@include tabbedNav;
}
