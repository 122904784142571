/* Single Image Element */

.heroPhotoElement {
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ccc;
  }
  .cutline {
    margin: 0;
    width: 100%;
    background-color: transparent;
    padding: 10px 0;
    font-size: 18px;
    @media only screen and (max-width: 768px){
      font-size: 13px;
      line-height: 1.3em;
    }
  }
}
